import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import CashOutForm from '@/components/Seller/cash-out-form';

export default function CashOut({
  isOpenDialog,
  setOpenDialog,
}: {
  isOpenDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={isOpenDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="text-center sm:max-w-xs">
          <div className="flex flex-col align-middle justify-center">
            <p className="text-lg font-bold">{t('cash_out_amount')}</p>
            <p className="text-sm pb-3">{t('enter_cash_out_amount')}</p>
            <div>
              <CashOutForm setOpenDialog={setOpenDialog} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
