import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button, buttonVariants } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { usePostLoginPartnerMutation } from '@/api/services/Partner/login';
import { TLoginPartner, ZLoginPartner } from '@/types/Partner/login';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { ErrorResponse, Link, useNavigate } from 'react-router-dom';
import { PasswordField } from '@/components/ui/password-field';
import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import {usePostHog} from "posthog-js/react";

type PartnerAuthFormProps = React.HTMLAttributes<HTMLDivElement>;

export function LoginForm({ className, ...props }: PartnerAuthFormProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [postLoginPartner, { isLoading: isLoadingLogin }] = usePostLoginPartnerMutation();
  const navigate = useNavigate();
  const posthog = usePostHog()

  const form = useForm<TLoginPartner>({
    resolver: zodResolver(ZLoginPartner),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (values: TLoginPartner) => {
    await postLoginPartner(values)
      .unwrap()
      .then(res => {
        posthog?.identify(values.username, {
          username: values.username,
        })
        posthog?.capture('clicked_log_in')
        if (res.registrationToken) {
          navigate(`/partner/register/${res.registrationToken}/pending`);
        } else {
          localStorage.setItem('token', res.token);
          localStorage.setItem('tokenType', 'partner');
          navigate('/partner/demands');
        }
      })
      .catch((error: ErrorResponse) => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('password_incorrect'),
        });
      });
  };

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="username"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('email')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('password')}</FormLabel>
                      <FormControl>
                        <PasswordField {...field} />
                      </FormControl>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div className="grid justify-items-end pb-5">
              <div className="text-sm">
                <Link
                  to="/partner/reset-password"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  {t('forgot_password')}
                </Link>
              </div>
            </div>
            {isLoadingLogin ? (
              <Button disabled>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t('login.partner.button')}
              </Button>
            ) : (
              <>
                <Button type="submit">{t('login.partner.button')}</Button>
              </>
            )}
            <Link to={'/'} className={cn(buttonVariants({ variant: 'ghost' }), '')}>
              {t('login.seller.go_to_seller')}
            </Link>
          </div>
        </form>
      </Form>
    </div>
  );
}
