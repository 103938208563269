import { navItemsSeller } from '@/constants/data';
import SidebarDesktopSeller from '@/components/Seller/layout/sidebar-desktop-seller';
import Header from '@/components/Seller/layout/header';
import { useTranslation } from 'react-i18next';
import { AspectRatio } from '@/components/ui/aspect-ratio';

export default function Shops() {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopSeller navItems={navItemsSeller} title="" />
        <div className="flex flex-col">
          <Header navItems={navItemsSeller} title={t('shops_title')} type="dashboard" />
          <main className="p-6">
            <div className="Container">
              <AspectRatio ratio={16 / 9}>
                {/*<img*/}
                {/*  className="Image"*/}
                {/*  src="https://images.unsplash.com/photo-1535025183041-0991a977e25b?w=300&dpr=2&q=80"*/}
                {/*  alt="Landscape photograph by Tobias Tullius"*/}
                {/*/>*/}
              </AspectRatio>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
