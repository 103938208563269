import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { usePostOnboardingSellerMutation } from '@/api/services/Seller/onboarding';
import {
  OnboardingInformation,
  TOnboardingTermsForm,
  TPreOnboardingForm,
  ZOnboardingTermsFormSchema,
  ZPreOnboardingFormSchema,
} from '@/types/Seller/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import CGUV from '@/locales/fr/cgv';
import { Checkbox } from '@/components/ui/checkbox';
import { useNavigate } from 'react-router-dom';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';

export function RegisterForm() {
  useState<OnboardingInformation>();
  const [openTerms, setOpenTerm] = useState(false);
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { data: me, isError: isErrorMe } = useGetMeSellerQuery();

  useEffect(() => {
    if (isErrorMe) {
      toast({
        variant: 'destructive',
        title: t('an_error_occurred'),
      });
      navigate('/');
    }
    if (me) {
      if (me.onBoarded) {
        navigate('/onboarding');
      }
    }
  }, [me, isErrorMe]);

  const [postOnboarding, { isLoading: isLoadingOnboarding }] =
    usePostOnboardingSellerMutation();

  const form = useForm<TPreOnboardingForm>({
    resolver: zodResolver(ZPreOnboardingFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      terms: false,
    },
  });

  const formTerms = useForm<TOnboardingTermsForm>({
    resolver: zodResolver(ZOnboardingTermsFormSchema),
    defaultValues: {
      terms: false,
    },
  });

  const onSubmit = () => {
    setOpenTerm(true);
  };

  const onSubmitTerms = async (values: TPreOnboardingForm) => {
    values.terms = true;
    await postOnboarding(values)
      .unwrap()
      .then(() => {
        setOpenTerm(false);
        toast({
          variant: 'success',
          title: t('register.successful'),
        });
        navigate('/onboarding');
      })
      .catch(error => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <div className="grid gap-1">
                <FormLabel>{t('firstname')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <div className="grid gap-1">
                <FormLabel>{t('lastname')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <div className="grid gap-1">
          <Button type="submit">{t('register.seller.button')}</Button>
        </div>
      </form>
      <Dialog open={openTerms} onOpenChange={setOpenTerm}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('cgv')}</DialogTitle>
            <br />
            <DialogDescription
              className="text-black"
              style={{ maxHeight: '60vh', overflow: 'auto' }}
            >
              <CGUV />
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Form {...formTerms}>
              <form onSubmit={form.handleSubmit(onSubmitTerms)} className="space-y-6">
                <div className="flex items-center space-x-2">
                  <FormField
                    control={form.control}
                    name="terms"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            required={true}
                          />
                        </FormControl>
                        <FormLabel>
                          <span className="ml-2">{t('accept_terms')}</span>
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  {isLoadingOnboarding ? (
                    <Button disabled>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t('accept')}
                    </Button>
                  ) : (
                    <Button type="submit">{t('accept')}</Button>
                  )}
                </div>
              </form>
            </Form>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Form>
  );
}
