import { Link } from 'react-router-dom';
import { ChevronRight, CircleAlert, Hourglass, Lock } from 'lucide-react';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import AnimatedGradientText from '@/components/magicui/animated-gradient-text';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';

export default function Banner() {
  const { data: me } = useGetMeSellerQuery();
  const { t } = useTranslation();

  const kycStatus = me?.kycStatus;
  const isPending = kycStatus === ONBOARDING_STATUS_SELLER.PENDING;
  const isNotStarted = kycStatus === ONBOARDING_STATUS_SELLER.NOT_STARTED;
  const isRejected = kycStatus === ONBOARDING_STATUS_SELLER.REJECTED;

  return me?.kycStatus &&
    [
      ONBOARDING_STATUS_SELLER.NOT_STARTED,
      ONBOARDING_STATUS_SELLER.PENDING,
      ONBOARDING_STATUS_SELLER.REJECTED,
    ].includes(me.kycStatus) ? (
    <Link to={'/onboarding'}>
      <div className={cn('rounded-lg m-3', isNotStarted && '', isPending && '')}>
        <AnimatedGradientText>
          <div className="flex items-center align-middle justify-between p-3">
            <div className="mr-3">
              {isNotStarted ? (
                <Lock className="h-5 w-5 text-orange-900" />
              ) : isRejected ? (
                <CircleAlert className="h-5 w-5 text-red-700" />
              ) : (
                <Hourglass className="h-5 w-5 text-orange-900" />
              )}
            </div>
            <div className="text-sm font-medium">
              {isNotStarted
                ? t('banner.onboarding_not_started_button')
                : isPending
                  ? t('banner.onboarding_pending_button')
                  : isRejected
                    ? t('banner.onboarding_rejected_button')
                    : ''}
            </div>
            <div>
              <ChevronRight className="h-5 w-5" />
            </div>
          </div>
        </AnimatedGradientText>
      </div>
    </Link>
  ) : (
    <></>
  );
}
