import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import {
  Size,
  TEditItemForm,
  TInventory,
  ZEditItemFormSchema,
} from '@/types/Seller/inventory';
import { usePutInventorySellerMutation } from '@/api/services/Seller/inventory';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGetReferencesSellerQuery } from '@/api/services/Seller/onboarding';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';

interface EditFormProps extends React.HTMLAttributes<HTMLDivElement> {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  itemSelected: TInventory;
}

export function EditItemForm({
  itemSelected,
  setOpenDialog,
  className,
  ...props
}: EditFormProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [sizes, setSizes] = useState<Size[]>([]);
  const { data: references } = useGetReferencesSellerQuery();
  const [putItem, { isLoading: isLoadingPutItem }] = usePutInventorySellerMutation();

  useEffect(() => {
    if (references && itemSelected) {
      const { productCategory, gender } = itemSelected;

      if (productCategory === 'sneakers') {
        setSizes(references.sizes.sneakers[gender] || []);
      } else {
        const defaultSizes = references.sizes[productCategory].default;
        setSizes(defaultSizes);
      }
    }
  }, [itemSelected, references]);

  const form = useForm<TEditItemForm>({
    resolver: zodResolver(ZEditItemFormSchema),
    defaultValues: {
      size: itemSelected.sizeId,
      price: itemSelected.listPrice,
    },
  });

  const onSubmit = async (values: TEditItemForm) => {
    values.id = itemSelected.id;
    await putItem(values)
      .unwrap()
      .then(() => {
        toast({
          variant: 'success',
          title: t('article_updated_successfully'),
        });
        setOpenDialog(false);
      })
      .catch(error => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <div className={cn('grid gap-6 mt-5', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="size"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('size')}</FormLabel>
                      <FormControl>
                        <Select onValueChange={field.onChange} value={`${field.value}`}>
                          <SelectTrigger className="w-full">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {sizes.map(size => (
                              <SelectItem key={size.id} value={`${size.id}`}>
                                {size.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('price')}</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
          {isLoadingPutItem ? (
            <Button disabled variant="primary" className="w-full mt-2">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('submit_validation')}
            </Button>
          ) : (
            <>
              <Button type="submit" variant="primary" className="w-full mt-2">
                {t('edit_item')}
              </Button>
            </>
          )}
        </form>
      </Form>
    </div>
  );
}
