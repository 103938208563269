import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import {
  TCounterOfferForm,
  TCounterOfferPost,
  TOffers,
  ZCounterOfferForm,
} from '@/types/Seller/offers';
import { usePostNewCounterOfferPartnerMutation } from '@/api/services/Partner/offers';

interface EditFormProps extends React.HTMLAttributes<HTMLDivElement> {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  itemSelected: TOffers;
}

export function CounterOfferForm({
  itemSelected,
  setOpenDialog,
  className,
  ...props
}: EditFormProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [postNewCounterOffer, { isLoading: isLoadingPutItem }] =
    usePostNewCounterOfferPartnerMutation();

  const form = useForm<TCounterOfferForm>({
    resolver: zodResolver(ZCounterOfferForm),
    defaultValues: {
      id: 0,
      oldPrice:
        itemSelected.proposals.length > 0
          ? itemSelected.proposals[itemSelected.proposals.length - 1]?.price
          : Number(itemSelected.listPrice),
      newPrice:
        itemSelected.proposals.length > 0
          ? itemSelected.proposals[itemSelected.proposals.length - 1]?.price
          : Number(itemSelected.listPrice),
    },
  });

  const onSubmit = async (values: TCounterOfferForm) => {
    const body = {
      id: 0,
      price: 0,
    } as TCounterOfferPost;
    body.id = itemSelected.id;
    body.price = parseFloat(`${values.newPrice}`);
    await postNewCounterOffer(body)
      .unwrap()
      .then(() => {
        toast({
          variant: 'success',
          title: t('counter_offer_successfully'),
        });
        setOpenDialog(false);
      })
      .catch(error => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <div className={cn('grid gap-6 mt-5', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="oldPrice"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('old_price')}</FormLabel>
                      <FormControl>
                        <Input disabled={true} type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="newPrice"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('new_price')}</FormLabel>
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
          {isLoadingPutItem ? (
            <Button disabled variant="primary" className="w-full mt-2">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('submit_validation')}
            </Button>
          ) : (
            <>
              <Button type="submit" variant="primary" className="w-full mt-2">
                {t('counter_offer_submit')}
              </Button>
            </>
          )}
        </form>
      </Form>
    </div>
  );
}
