import { useTranslation } from 'react-i18next';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';

export default function OnboardingStepper() {
  const { data: me } = useGetMeSellerQuery();
  const { t } = useTranslation();

  const activeItemText = 'text-indigo-600 dark:text-indigo-500';
  const activeItemErrorText = 'text-red-600 dark:text-red-500';
  const activeItemNumber = 'border-indigo-600 dark:border-indigo-500';
  const activeItemErrorNumber = 'border-red-600 dark:border-red-500';
  const inactiveItemText = 'text-gray-500 dark:text-gray-400';
  const inactiveItemNumber = 'border-gray-500 dark:border-gray-400';

  return (
    <ol className="space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
      <li
        className={`flex items-center space-x-2.5 rtl:space-x-reverse ${me?.kycStatus === ONBOARDING_STATUS_SELLER.REJECTED ? activeItemErrorText : me?.kycStatus === ONBOARDING_STATUS_SELLER.NOT_STARTED ? activeItemText : inactiveItemText}`}
      >
        <span
          className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${me?.kycStatus === ONBOARDING_STATUS_SELLER.REJECTED ? activeItemErrorNumber : me?.kycStatus === ONBOARDING_STATUS_SELLER.NOT_STARTED ? activeItemNumber : inactiveItemNumber}}`}
        >
          1
        </span>
        <span>
          <h3 className="font-medium leading-tight">{t('onboarding.step1_title')}</h3>
          <p className="text-sm">{t('onboarding.step1_description')}</p>
        </span>
      </li>
      <li
        className={`flex items-center space-x-2.5 rtl:space-x-reverse ${me?.kycStatus === ONBOARDING_STATUS_SELLER.PENDING ? activeItemText : inactiveItemText}`}
      >
        <span
          className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${me?.kycStatus === ONBOARDING_STATUS_SELLER.PENDING ? activeItemNumber : inactiveItemNumber}}`}
        >
          2
        </span>
        <span>
          <h3 className="font-medium leading-tight">{t('onboarding.step2_title')}</h3>
          <p className="text-sm">{t('onboarding.step2_description')}</p>
        </span>
      </li>
      <li
        className={`flex items-center space-x-2.5 rtl:space-x-reverse ${me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED ? activeItemText : inactiveItemText}`}
      >
        <span
          className={`flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED ? activeItemNumber : inactiveItemNumber}}`}
        >
          3
        </span>
        <span>
          <h3 className="font-medium leading-tight">{t('onboarding.step3_title')}</h3>
          <p className="text-sm">{t('onboarding.step3_description')}</p>
        </span>
      </li>
    </ol>
  );
}
