import { Skeleton } from '@/components/ui/skeleton';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { getInitials } from '@/lib/utils';
import { useGetMePartnerQuery } from '@/api/services/Partner/me';

export default function AvatarIconPartner() {
  const { data: me, isLoading, isFetching } = useGetMePartnerQuery();

  return (
    <>
      <Avatar className="border border-input shadow-sm h-9 w-9">
        {!me || isLoading || isFetching ? (
          <Skeleton className="h-12 w-12 rounded-full" />
        ) : (
          <>
            <AvatarImage src={''} alt={''} />
            <AvatarFallback>
              {getInitials(`${me?.firstName} ${me?.lastName}`)}
            </AvatarFallback>
          </>
        )}
      </Avatar>
    </>
  );
}
