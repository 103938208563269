import { z } from 'zod';

export const ZAuthenticateForm = z
  .object({
    isValid: z.string(),
    description: z.string().optional(),
    firstPicture: z.string().optional(),
    secondPicture: z.string().optional(),
    thirdPicture: z.string().optional(),
  })
  .superRefine(
    ({ isValid, description, firstPicture, secondPicture, thirdPicture }, ctx) => {
      if (isValid === 'no') {
        if (description === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'required_field',
            path: ['description'],
          });
        }
        if (firstPicture === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'required_field',
            path: ['firstPicture'],
          });
        }
        if (secondPicture === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'required_field',
            path: ['secondPicture'],
          });
        }
        if (thirdPicture === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'required_field',
            path: ['thirdPicture'],
          });
        }
      }
    },
  );

export type TAuthenticateForm = z.infer<typeof ZAuthenticateForm>;
