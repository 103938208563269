import { navItemsSeller } from '@/constants/data';
import SidebarDesktopSeller from '@/components/Seller/layout/sidebar-desktop-seller';
import Header from '@/components/Seller/layout/header';
import { useTranslation } from 'react-i18next';
import { OffersTable } from '@/components/Seller/table-offers';
import { useGetOffersSellerQuery } from '@/api/services/Seller/offers';
import OffersInsightCard from '@/components/Seller/offers-insight-card';

export default function OffersSeller() {
  const { t } = useTranslation();

  const { data: offers } = useGetOffersSellerQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <div className="lg:grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopSeller navItems={navItemsSeller} title="" />
        <div className="flex flex-col">
          <Header
            navItems={navItemsSeller}
            title={t('discover_offers')}
            type="dashboard"
          />
          <main className="p-6">
            <div className="space-y-4">
              <OffersInsightCard />
              <OffersTable data={offers || []} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
