import { api } from '../api';
import { TInventory, TInventoryStatistics, TItem } from '@/types/Seller/inventory';

export const InventoryApiSeller = api.injectEndpoints({
  endpoints: build => ({
    getInventorySeller: build.query<TInventory[], void>({
      query: () => ({
        url: `seller/inventory/items`,
        method: 'GET',
      }),
      transformResponse: (response: TInventory[]) => {
        return response.map(item => ({
          ...item,
          updatedAt: item.updatedAt === null ? item.createdAt : item.updatedAt,
        }));
      },
      providesTags: ['SellerInventory'],
    }),
    postInventorySeller: build.mutation<[], TItem>({
      query: body => ({
        url: `seller/inventory/items`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['SellerInventory', 'InventoryKPISeller'],
    }),
    putInventorySeller: build.mutation<void, TItem>({
      query: body => ({
        url: `seller/inventory/items/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['SellerInventory', 'InventoryKPISeller'],
    }),
    deleteInventorySeller: build.mutation<[], number>({
      query: id => ({
        url: `seller/inventory/items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SellerInventory', 'InventoryKPISeller'],
    }),
    getInventoryStatisticsSeller: build.query<TInventoryStatistics, void>({
      query: () => ({
        url: `seller/inventory-statistics`,
        method: 'GET',
      }),
      providesTags: ['InventoryKPISeller'],
    }),
  }),
});

export const {
  usePostInventorySellerMutation,
  usePutInventorySellerMutation,
  useGetInventorySellerQuery,
  useDeleteInventorySellerMutation,
  useGetInventoryStatisticsSellerQuery,
} = InventoryApiSeller;
