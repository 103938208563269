import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../../ui/skeleton';
import DropdownItems from '@/components/Seller/layout/dropdows-items';
import AvatarIcon from '@/components/Seller/layout/avatar';

export function DropdownNavbar() {
  const { t } = useTranslation();
  const { data: me, isLoading, isFetching } = useGetMeSellerQuery();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <span className="flex gap-3 rounded-lg px-3 py-3 transition-all">
          <AvatarIcon />
          <span>
            {t('my_profile')}
            <br />
            {!me || isLoading || isFetching ? (
              <Skeleton className="h-4 w-[150px]" />
            ) : (
              <p className="text-xs leading-none text-muted-foreground text-ellipsis">{`${me?.email.substr(0, 22)}${me?.email?.length > 22 ? '...' : ''}`}</p>
            )}
          </span>
        </span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-59 ms-1" align="end" forceMount>
        <DropdownItems />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
