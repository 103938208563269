import Header from '@/components/Seller/layout/header';
import SidebarDesktopSeller from '@/components/Seller/layout/sidebar-desktop-seller';
import { navItemsSeller } from '@/constants/data';
import { useTranslation } from 'react-i18next';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';

export default function ProfileLayoutSeller({
  children,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  const { t } = useTranslation();
  const { data: me } = useGetMeSellerQuery();

  return (
    <>
      <div className="grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopSeller navItems={navItemsSeller} title={''} />
        <div className="flex flex-col">
          <Header
            navItems={navItemsSeller}
            title={
              me?.kycStatus !== ONBOARDING_STATUS_SELLER.APPROVED
                ? t('onboarding.title')
                : ''
            }
            type="profile"
          />
          <main className="flex flex-1 flex-col p-4 lg:p-6">{children}</main>
        </div>
      </div>
    </>
  );
}
