import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { OFFER_STATUS, TOffers } from '@/types/Seller/offers.ts';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import StatusBadge from '@/components/status-badge.tsx';
import { formattedCurrency, getLocale } from '@/lib/utils.ts';
import priceHistory from '@/components/price-history-table.tsx';
import { format, getUnixTime } from 'date-fns';
import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast.ts';
import {
  usePostAcceptOfferSellerMutation,
  usePostRefuseOfferSellerMutation,
} from '@/api/services/Seller/offers.ts';
import EditItem from '@/components/Seller/edit-item.tsx';
import CounterOffer from '@/components/Seller/counter-offer.tsx';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog.tsx';
import { ArrowLeftRight, Check, Loader2, Package, Truck, X } from 'lucide-react';
import TrackingForm from '@/components/Seller/tracking-form.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip.tsx';

const multiColumnFilterFn: FilterFn<TOffers> = (row, _columnId, filterValue: string) => {
  const searchableRowContent = `${row.original.name} ${row.original.sku} ${row.original.slug}`;
  const searchableRowContentLower = searchableRowContent.toLowerCase();
  const filterValueLower = filterValue.toLowerCase();
  return searchableRowContentLower.includes(filterValueLower);
};

export const columnsOffers: ColumnDef<TOffers>[] = [
  {
    accessorKey: 'slug',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button variant="ghost" className="text-black">
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      if (row.getValue('slug')) {
        return <Badge variant="outline">{row.getValue('slug')}</Badge>;
      } else {
        return <></>;
      }
    },
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      const { t } = useTranslation();
      return <p className="text-black"> {t(`inventory.columns.${column.id}`)}</p>;
    },
    filterFn: multiColumnFilterFn,
    cell: ({ row }) => {
      return (
        <div className="flex md:flex-row flex-col align-middle">
          <div>
            <img
              alt={row.original.name}
              src={row.original.imageUrl}
              className="w-12 rounded m-3"
            />
          </div>
          <div>
            <div className="font-semibold">{row.getValue('name')}</div>
            <div className="text-sm text-muted-foreground">{row.original.sku}</div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: 'offerType',
    header: () => {
      const { t } = useTranslation();
      return (
        <Button variant="ghost" className="text-black cursor-auto">
          {t(`type_offers`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      const { t } = useTranslation();
      return (
        <span
          key={`${row.id}offerType`}
          className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-700/10"
        >
          {t('purchase')}
        </span>
      );
    },
  },
  {
    accessorKey: 'status',
    header: () => {
      const { t } = useTranslation();
      return (
        <Button variant="ghost" className="text-black cursor-auto">
          {t(`status`)}
        </Button>
      );
    },
    filterFn: (row, id, value: string[]) => {
      return value.includes(row.getValue(id));
    },
    cell: ({ row }) => {
      return <StatusBadge offer={row.original} />;
    },
  },
  {
    accessorKey: 'size',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue('size')}</div>;
    },
  },
  {
    accessorKey: 'listPrice',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t(`request_price`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      const proposals = row.original.proposals;
      const amount =
        proposals.length > 0
          ? proposals[proposals.length - 1].price
          : row.original.listPrice;
      if (
        [
          OFFER_STATUS.SELLER_ACCEPTED,
          OFFER_STATUS.PARTNER_ACCEPTED,
          OFFER_STATUS.SELLER_WAITING_SHIPPING,
          OFFER_STATUS.SELLER_SHIPPED,
          OFFER_STATUS.ITEM_AUTHENTICATED_FAILED,
          OFFER_STATUS.ITEM_AUTHENTICATED_SUCCESS,
        ].includes(row.original.status)
      ) {
        return <div className="text-left font-bold">{formattedCurrency(amount)}</div>;
      } else {
        if (proposals.length > 0) {
          return priceHistory({
            article: row.original,
            light: true,
          });
        } else {
          return <div className="text-left font-bold">{formattedCurrency(amount)}</div>;
        }
      }
    },
  },
  {
    accessorKey: 'updatedAt',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    sortingFn: (rowA, rowB) => {
      return (
        getUnixTime(new Date(rowA.original.updatedAt)) -
        getUnixTime(new Date(rowB.original.updatedAt))
      );
    },
    cell: ({ row }) => (
      <span>
        {format(row.getValue('updatedAt'), 'dd/MM/yyyy hh:mm:ss', {
          locale: getLocale(),
        })}
      </span>
    ),
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
      const [openConfirmAccept, setOpenConfirmAccept] = useState(false);
      const [openCounterOffer, setOpenCounterOffer] = useState(false);
      const [openShipping, setOpenShipping] = useState(false);
      const [isEditDialog, setEditDialog] = useState(false);
      const { t } = useTranslation();
      const { toast } = useToast();
      const item = row.original;
      const [postRefuseOffer, { isLoading: isLoadingRefuseOffer }] =
        usePostRefuseOfferSellerMutation();
      const [postAcceptOffer, { isLoading: isLoadingAcceptOffer }] =
        usePostAcceptOfferSellerMutation();

      return (
        <>
          <EditItem
            isOpenDialog={isEditDialog}
            setOpenDialog={setEditDialog}
            article={row.original}
          />
          <CounterOffer
            isOpenDialog={openCounterOffer}
            setOpenDialog={setOpenCounterOffer}
            article={row.original}
          />
          <AlertDialog open={openConfirmDelete}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{t('confirm_refuse_offer')}</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant="outline" onClick={() => setOpenConfirmDelete(false)}>
                    {t('cancel')}
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  {isLoadingRefuseOffer ? (
                    <Button disabled>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t('refuse')}
                    </Button>
                  ) : (
                    <Button
                      className="bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900"
                      onClick={() =>
                        postRefuseOffer(item.id)
                          .unwrap()
                          .then(() => {
                            toast({
                              variant: 'success',
                              title: t('refuse_successfully'),
                            });
                            setOpenConfirmDelete(false);
                          })
                          .catch(() => {
                            toast({
                              variant: 'destructive',
                              title: t('an_error_occurred'),
                            });
                            setOpenConfirmDelete(false);
                          })
                      }
                    >
                      {t('refuse')}
                    </Button>
                  )}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog open={openConfirmAccept}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{t('confirm_accept_offer')}</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant="outline" onClick={() => setOpenConfirmAccept(false)}>
                    {t('cancel')}
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  {isLoadingAcceptOffer ? (
                    <Button disabled>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t('accept')}
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        postAcceptOffer(item.id)
                          .unwrap()
                          .then(() => {
                            toast({
                              variant: 'success',
                              title: t('accept_successfully'),
                            });
                            setOpenConfirmAccept(false);
                          })
                          .catch(() => {
                            toast({
                              variant: 'destructive',
                              title: t('an_error_occurred'),
                            });
                            setOpenConfirmAccept(false);
                          })
                      }
                    >
                      {t('accept')}
                    </Button>
                  )}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog open={openShipping}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{t('confirm_shipping')}</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogDescription>
                <TrackingForm setOpenDialog={setOpenShipping} offer={row.original} />
              </AlertDialogDescription>
            </AlertDialogContent>
          </AlertDialog>
          <TooltipProvider>
            <div className="flex space-x-1">
              {[OFFER_STATUS.SELLER_PENDING_RESPONSE].includes(row.original.status) && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <Button
                        variant="primaryLight"
                        size="icon"
                        onClick={() => setOpenConfirmAccept(true)}
                      >
                        <span className="sr-only">{t('accept')}</span>
                        <Check className="h-4 w-4" />
                      </Button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('accept')}</p>
                  </TooltipContent>
                </Tooltip>
              )}
              {[OFFER_STATUS.SELLER_PENDING_RESPONSE].includes(row.original.status) && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <Button
                        variant="warning"
                        size="icon"
                        onClick={() => setOpenCounterOffer(true)}
                      >
                        <span className="sr-only">{t('counter_offer')}</span>
                        <ArrowLeftRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('counter_offer')}</p>
                  </TooltipContent>
                </Tooltip>
              )}
              {![
                OFFER_STATUS.PARTNER_REFUSED,
                OFFER_STATUS.SELLER_REFUSED,
                OFFER_STATUS.SELLER_ACCEPTED,
                OFFER_STATUS.PARTNER_ACCEPTED,
                OFFER_STATUS.SELLER_WAITING_SHIPPING,
                OFFER_STATUS.SELLER_SHIPPED,
                OFFER_STATUS.ITEM_AUTHENTICATED_FAILED,
                OFFER_STATUS.ITEM_AUTHENTICATED_SUCCESS,
              ].includes(row.original.status) && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <Button
                        variant="danger"
                        size="icon"
                        onClick={() => setOpenConfirmDelete(true)}
                      >
                        <span className="sr-only">{t('refuse')}</span>
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('refuse')}</p>
                  </TooltipContent>
                </Tooltip>
              )}
              {OFFER_STATUS.SELLER_WAITING_SHIPPING === row.original.status && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <Button
                        variant="yellow"
                        size="icon"
                        onClick={() => setOpenShipping(true)}
                      >
                        <span className="sr-only">{t('shipping')}</span>
                        <Truck className="h-4 w-4" />
                      </Button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{openShipping ? t('shipping') : t('shipping')}</p>
                  </TooltipContent>
                </Tooltip>
              )}
              {OFFER_STATUS.SELLER_SHIPPED === row.original.status && (
                <Tooltip>
                  <TooltipTrigger>
                    <div>
                      <Button
                        variant="yellow"
                        size="icon"
                        onClick={() => window.open(row.original.trackingUrl, '_blank')}
                      >
                        <span className="sr-only">{t('tracking_url_info')}</span>
                        <Package className="h-4 w-4" />
                      </Button>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('tracking_url_info')}</p>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </TooltipProvider>
        </>
      );
    },
  },
];
