import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CashInForm from '@/components/Partner/cash-in-form';
import { useSearchParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { usePostAcceptOfferPartnerMutation } from '@/api/services/Partner/offers';
import { useToast } from '@/components/ui/use-toast';

export default function CashIn({
  isOpenDialog,
  setOpenDialog,
}: {
  isOpenDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [postAcceptOffer] = usePostAcceptOfferPartnerMutation();
  const { t } = useTranslation();
  const { toast } = useToast();

  useEffect(() => {
    const status = searchParams.get('status');
    const offerId = searchParams.get('offerId');
    if (status === 'success') {
      if (offerId) {
        postAcceptOffer({
          id: Number(offerId),
          body: {
            paymentMethod: 'balance',
          },
        })
          .unwrap()
          .then(() => {
            toast({
              variant: 'success',
              title: t('accept_successfully'),
            });
            setOpenStatus(true);
          })
          .catch(() => {
            toast({
              variant: 'destructive',
              title: t('an_error_occurred'),
            });
            setOpenStatus(true);
          });
      } else {
        setOpenStatus(true);
        setSearchParams('');
      }
    } else if (status === 'error') {
      setOpenStatus(true);
      setSearchParams('');
    }
  }, []);

  return (
    <>
      <AlertDialog open={openStatus} onOpenChange={setOpenStatus}>
        <AlertDialogContent>
          <AlertDialogHeader className="sm:text-center">
            <AlertDialogTitle>
              <div className="flex flex-row justify-center align-middle pb-2">
                <div>
                  {searchParams.get('status') === 'success' ? (
                    <Check className="h-12 w-12 text-green-600" />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {searchParams.get('status') === 'success'
                ? t('cash_in_successfully', { amount: searchParams.get('amount') })
                : t('cash_in_error')}
              <br />
              <Button
                onClick={() => setOpenStatus(false)}
                className="mt-8"
                variant="default"
              >
                {t('continue')}
              </Button>
            </AlertDialogTitle>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
      <Dialog open={isOpenDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="text-center sm:max-w-xs">
          <div className="flex flex-col align-middle justify-center">
            <p className="text-lg font-bold">{t('credit_account')}</p>
            <p className="text-sm pb-3">{t('enter_amount')}</p>
            <div>
              <CashInForm setOpenDialog={setOpenDialog} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
