import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  TAddressInformationForm,
  ZAddressFullFormSchema,
} from '@/types/Seller/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useGetAddressQuery, usePutAdressMutation } from '@/api/services/Seller/me';
import { AddressAutofill } from '@mapbox/search-js-react';
import { useEffect, useState } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Loader2 } from 'lucide-react';

export function AddressForm() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const { toast } = useToast();

  const {
    data: addressData,
    isLoading: isLoadingAddressData,
    isFetching: isFetchingAddressData,
  } = useGetAddressQuery();
  const [putAddresss, { isLoading: isLoadingAddress }] = usePutAdressMutation();

  const form = useForm<TAddressInformationForm>({
    resolver: zodResolver(ZAddressFullFormSchema),
    defaultValues: {
      shippingCity: '',
      shippingCountry: '',
      shippingStreet: '',
      shippingZipcode: '',
      billingStreet: '',
      billingZipcode: '',
      billingCity: '',
      billingCountry: '',
      sameAddress: true,
    },
  });

  const { watch } = form;

  useEffect(() => {
    if (addressData) {
      form.setValue('shippingStreet', addressData.shippingStreet);
      form.setValue('shippingZipcode', addressData.shippingZipcode);
      form.setValue('shippingCity', addressData.shippingCity);
      form.setValue('shippingCountry', addressData.shippingCountry);
      form.setValue('billingStreet', addressData.billingStreet);
      form.setValue('billingZipcode', addressData.billingZipcode);
      form.setValue('billingCity', addressData.billingCity);
      form.setValue('billingCountry', addressData.billingCountry);
      form.setValue('sameAddress', addressData.sameAddress);
    }
  }, [addressData, isLoadingAddressData, isFetchingAddressData]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [isLoadingAddressData, isFetchingAddressData]);

  const onSubmit = async (values: TAddressInformationForm) => {
    if (values.sameAddress) {
      values.billingCity = values.shippingCity;
      values.billingZipcode = values.shippingZipcode;
      values.billingStreet = values.shippingStreet;
      values.billingCountry = values.shippingCountry;
    }
    await putAddresss(values)
      .unwrap()
      .then(() => {
        toast({
          variant: 'success',
          title: t('save_successful'),
        });
      })
      .catch(error => {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <>
          {[0, 1].map(() => {
            return (
              <>
                <Skeleton className="h-8 w-[350px] mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
              </>
            );
          })}
        </>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
            <div>
              <h3 className="text-xl font-medium pb-3">
                {watch('sameAddress')
                  ? t('shipping_billing_address')
                  : t('shipping_address')}
              </h3>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <FormField
                  control={form.control}
                  name="shippingStreet"
                  render={({ field }) => (
                    <FormItem>
                      <div className="grid gap-1">
                        <FormLabel>{t('address')}</FormLabel>
                        <FormControl>
                          <AddressAutofill accessToken={`${process.env.MAPBOX_API_KEY}`}>
                            <Input autoComplete="address-line1" {...field} />
                          </AddressAutofill>
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="shippingCity"
                  render={({ field }) => (
                    <FormItem>
                      <div className="grid gap-1">
                        <FormLabel>{t('city')}</FormLabel>
                        <FormControl>
                          <Input {...field} autoComplete="address-level2" />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="shippingZipcode"
                  render={({ field }) => (
                    <FormItem>
                      <div className="grid gap-1">
                        <FormLabel>{t('postcode')}</FormLabel>
                        <FormControl>
                          <Input {...field} autoComplete="postal-code" />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="shippingCountry"
                  render={({ field }) => (
                    <FormItem>
                      <div className="grid gap-1">
                        <FormLabel>{t('country')}</FormLabel>
                        <FormControl>
                          <Input {...field} autoComplete="country-name" />
                        </FormControl>
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div>
                <FormField
                  control={form.control}
                  name="sameAddress"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel> {t('shipping_billing_is_different_address')}</FormLabel>
                    </FormItem>
                  )}
                />
              </div>
              {/* Billing Address*/}
              {watch('sameAddress') === false && (
                <>
                  <div>
                    <h3 className="text-xl font-medium pb-3">{t('billing_address')}</h3>
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="billingStreet"
                      render={({ field }) => (
                        <FormItem>
                          <div className="grid gap-1">
                            <FormLabel>{t('address')}</FormLabel>
                            <FormControl>
                              <AddressAutofill
                                accessToken={`${process.env.MAPBOX_API_KEY}`}
                              >
                                <Input autoComplete="address-line1" {...field} />
                              </AddressAutofill>
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="billingCity"
                      render={({ field }) => (
                        <FormItem>
                          <div className="grid gap-1">
                            <FormLabel>{t('city')}</FormLabel>
                            <FormControl>
                              <Input {...field} autoComplete="address-level2" />
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="billingZipcode"
                      render={({ field }) => (
                        <FormItem>
                          <div className="grid gap-1">
                            <FormLabel>{t('postcode')}</FormLabel>
                            <FormControl>
                              <Input {...field} autoComplete="postal-code" />
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <FormField
                      control={form.control}
                      name="billingCountry"
                      render={({ field }) => (
                        <FormItem>
                          <div className="grid gap-1">
                            <FormLabel>{t('country')}</FormLabel>
                            <FormControl>
                              <Input {...field} autoComplete="country-name" />
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="grid gap-1 mt-6">
              {isLoadingAddress ? (
                <Button disabled>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {t('save_modifications')}
                </Button>
              ) : (
                <>
                  <Button type="submit">{t('save_modifications')}</Button>
                </>
              )}
            </div>
          </form>
        </Form>
      )}
    </>
  );
}
