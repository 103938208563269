import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TRegisterFormStep1,
  TRegisterFormStep2,
  TRegisterFormStep3,
  TRegisterFormStep4,
  TRegisterFormStep5,
} from '@/types/Partner/onboarding';

interface IState {
  seller: {
    registerPartnerStep1: TRegisterFormStep1;
    registerPartnerStep2: TRegisterFormStep2;
    registerPartnerStep3: TRegisterFormStep3;
    registerPartnerStep4: TRegisterFormStep4;
    registerPartnerStep5: TRegisterFormStep5;
  };
}

const initialState: IState = {
  seller: {
    registerPartnerStep1: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    registerPartnerStep2: {
      entityStreet: '',
      entityZipcode: '',
      entityCity: '',
      entityCountry: '',
      proofOfAddress: '',
    },
    registerPartnerStep3: {
      businessStatus: '',
      entityName: '',
      registrationNumber: '',
      businessType: 0,
      proofOfRegistration: '',
    },
    registerPartnerStep4: {
      holderName: '',
      iban: '',
      bic: '',
      proofOfBankAccount: '',
    },
    registerPartnerStep5: {
      representativeFirstName: '',
      representativeLastName: '',
      representativeBirthDate: '',
      representativeEmail: '',
      representativeNationality: '',
      accountingReport: '',
      backOfIdentityCard: '',
      frontOfIdentityCard: '',
    },
  },
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRegisterPartnerStep1: (state, action: PayloadAction<TRegisterFormStep1>) => {
      state.seller.registerPartnerStep1 = action.payload;
    },
    setRegisterPartnerStep2: (state, action: PayloadAction<TRegisterFormStep2>) => {
      state.seller.registerPartnerStep2 = action.payload;
    },
    setRegisterPartnerStep3: (state, action: PayloadAction<TRegisterFormStep3>) => {
      state.seller.registerPartnerStep3 = action.payload;
    },
    setRegisterPartnerStep4: (state, action: PayloadAction<TRegisterFormStep4>) => {
      state.seller.registerPartnerStep4 = action.payload;
    },
    setRegisterPartnerStep5: (state, action: PayloadAction<TRegisterFormStep5>) => {
      state.seller.registerPartnerStep5 = action.payload;
    },
  },
});

export const {
  setRegisterPartnerStep1,
  setRegisterPartnerStep2,
  setRegisterPartnerStep3,
  setRegisterPartnerStep4,
  setRegisterPartnerStep5,
} = app.actions;

export default app;
