import { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogContent } from '../ui/dialog';
import { TInventory } from '@/types/Seller/inventory';
import { EditItemForm } from '@/components/Seller/edit-item-form';

export default function EditItem({
  isOpenDialog,
  setOpenDialog,
  article,
}: {
  isOpenDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  article: TInventory;
}) {
  return (
    <Dialog open={isOpenDialog} onOpenChange={setOpenDialog}>
      <DialogContent className="lg:min-w-[800px]">
        {article && (
          <>
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2">
              <div>
                <img
                  src={`${article.imageUrl}`}
                  className="object-scale-down w-20 lg:w-auto p-3"
                  alt={`${article.name}`}
                />
              </div>
              <div>
                <p className="text-xl font-bold">{`${article.name}`}</p>
                <p>{`${article.sku}`}</p>
                <EditItemForm itemSelected={article} setOpenDialog={setOpenDialog} />
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
