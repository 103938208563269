import { number, z } from 'zod';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { differenceInYears } from 'date-fns';

export interface JobType {
  id: string | number;
  label: string;
}

export interface Revenue {
  id: string | number;
  label: string;
}

export interface Country {
  label: string;
  name: string;
}

export interface BusinessType {
  label: string;
  id: number;
}

export enum ONBOARDING_STATUS_SELLER {
  NOT_STARTED = 'NOT_STARTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export interface Size {
  id: number;
  label: string;
}

export interface OnboardingInformation {
  jobTypes: JobType[];
  revenues: Revenue[];
  countries: Country[];
  businessTypes: BusinessType[];
  sizes: {
    [key: string]: {
      [key: string]: Size[];
    };
  };
}

export const ZTermFormSchema = z.object({
  terms: z.boolean().optional(),
});

export const ZPreOnboardingFormSchema = ZTermFormSchema.extend({
  firstName: z.string().min(1, {
    message: 'required_field',
  }),
  lastName: z.string().min(2, {
    message: 'required_field',
  }),
});

export const ZPersonalInformationFormSchema = z.object({
  firstName: z.string().min(1, {
    message: 'required_field',
  }),
  lastName: z.string().min(2, {
    message: 'required_field',
  }),
  email: z.string().email({
    message: 'invalid_field',
  }),
  phone: z.string().refine(isValidPhoneNumber, { message: 'invalid_field' }),
});

export const ZBankInformationFormSchema = z.object({
  holderName: z.string().min(1, {
    message: 'required_field',
  }),
  iban: z.string().min(1, {
    message: 'required_field',
  }),
  bic: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZAddressFormSchema = z.object({
  shippingStreet: z.string().min(2, {
    message: 'required_field',
  }),
  shippingZipcode: z.string().min(2, {
    message: 'required_field',
  }),
  shippingCity: z.string().min(2, {
    message: 'required_field',
  }),
  shippingCountry: z.string().min(2, {
    message: 'required_field',
  }),
  billingStreet: z.string().optional(),
  billingZipcode: z.string().optional(),
  billingCity: z.string().optional(),
  billingCountry: z.string().optional(),
  sameAddress: z.boolean().optional(),
});

export const ZAddressBillingFormSchema = z.object({
  billingStreet: z.string().optional(),
  billingZipcode: z.string().optional(),
  billingCity: z.string().optional(),
});

export const ZAddressFullFormSchema = ZAddressFormSchema.merge(ZAddressBillingFormSchema);

export interface ITempOnboardingForm {
  holderName: string;
  iban: string;
  bic: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  shippingStreet: string;
  shippingZipcode: string;
  shippingCity: string;
  shippingCountry: string;
  billingStreet: string;
  billingZipcode: string;
  billingCity: string;
  billingCountry: string;
  sameAddress: boolean;
  birthDate: string;
  birthCountry: string;
  nationality: string;
  birthCity: string;
  jobType: string;
  revenue: string;
  proofOfBankAccount: string;
  frontOfIdentityCard: string;
  backOfIdentityCard: string;
}

export const ZOnboardingFormSchema = ZBankInformationFormSchema.merge(
  ZPersonalInformationFormSchema,
)
  .merge(ZAddressFormSchema)
  .extend({
    birthDate: z
      .date({
        required_error: 'required_field',
      })
      .refine(
        date => {
          const age = differenceInYears(new Date(), date);
          if (age < 18) {
            return false;
          } else {
            return true;
          }
        },
        {
          message: 'required_majority',
        },
      )
      .or(
        z.string().min(1, {
          message: 'required_field',
        }),
      ),
    birthCountry: z.string().min(2, {
      message: 'required_field',
    }),
    nationality: z.string().min(2, {
      message: 'required_field',
    }),
    birthCity: z.string().min(2, {
      message: 'required_field',
    }),
    jobType: z
      .string()
      .min(1, {
        message: 'required_field',
      })
      .or(
        number().min(1, {
          message: 'required_field',
        }),
      ),
    revenue: z
      .string()
      .min(1, {
        message: 'required_field',
      })
      .or(
        number().min(1, {
          message: 'required_field',
        }),
      ),
    proofOfBankAccount: z.string().min(1, {
      message: 'required_field',
    }),
    frontOfIdentityCard: z.string().min(1, {
      message: 'required_field',
    }),
    backOfIdentityCard: z.string().min(1, {
      message: 'required_field',
    }),
  });

export const ZOnboardingTermsFormSchema = z.object({
  terms: z.boolean(),
});

export type TOnboardingForm = z.infer<typeof ZOnboardingFormSchema>;
export type TPreOnboardingForm = z.infer<typeof ZPreOnboardingFormSchema>;
export type TOnboardingTermsForm = z.infer<typeof ZOnboardingTermsFormSchema>;
export type TPersonalInformationForm = z.infer<typeof ZPersonalInformationFormSchema>;
export type TBankInformationForm = z.infer<typeof ZBankInformationFormSchema>;
export type TAddressInformationForm = z.infer<typeof ZAddressFormSchema>;
export type TAddressFullForm = z.infer<typeof ZAddressFullFormSchema>;
