import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFR from '../src/locales/fr/translation.json';
import translationEN from '../src/locales/en/translation.json';

const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(err => console.info('something went wrong loading i18n', err));

export default i18n;
