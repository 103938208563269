import { z } from 'zod';

export const ZLoginPartner = z.object({
  username: z.string().min(1, {
    message: 'required_field',
  }),
  password: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZResetPasswordPartner = z.object({
  email: z.string().min(1, {
    message: 'required_field',
  }),
});

export interface TLoginPartnerToken {
  token: string;
  registrationToken?: string;
}

export interface TResetPassword {
  email: string;
}

export interface TResetPasswordLink {
  loginLink: string;
}

export interface TMagicLink {
  user: string;
  expires: number;
  hash: string;
}

export type TLoginPartner = z.infer<typeof ZLoginPartner>;
export type TResetPasswordPartner = z.infer<typeof ZResetPasswordPartner>;
