import * as React from 'react';
import { cn } from '@/lib/utils';
import { Icons } from '@/components/icons';
import { Button, buttonVariants } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type SellerAuthFormProps = React.HTMLAttributes<HTMLDivElement>;

export function LoginForm({ className, ...props }: SellerAuthFormProps) {
  const { t } = useTranslation();

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Button
        type="button"
        disabled={false}
        onClick={() => {
          window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${process.env.DISCORD_CLIENT_ID}&redirect_uri=${process.env.DISCORD_REDIRECT_URI}&response_type=code&scope=identify%20email`;
        }}
      >
        <Icons.discord className="mr-2 h-4 w-4 dark:fill-gray-900 fill-white " />
        {t('login.seller.button')}
      </Button>
      <Link
        to={'/partner/login'}
        className={cn(buttonVariants({ variant: 'ghost' }), '')}
      >
        {t('login.partner.go_to_partner')}
      </Link>
    </div>
  );
}
