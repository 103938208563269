import { api } from '../api';

export const bankPartnerApi = api.injectEndpoints({
  endpoints: build => ({
    postCashInPartner: build.mutation<
      {
        redirect_url: string;
      },
      {
        amount: number;
        offerId?: number;
      }
    >({
      query: body => ({
        url: 'partner/cash-in',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
  }),
});

export const { usePostCashInPartnerMutation } = bankPartnerApi;
