import { api } from '@/api/services/api';
import {
  TLoginPartner,
  TLoginPartnerToken,
  TResetPassword,
  TResetPasswordLink,
} from '@/types/Partner/login';

export const LoginApiPartner = api.injectEndpoints({
  endpoints: build => ({
    postLoginPartner: build.mutation<TLoginPartnerToken, TLoginPartner>({
      query: body => ({
        url: 'partner/login',
        method: 'POST',
        body: body,
      }),
    }),
    postResetPassword: build.mutation<TResetPasswordLink, TResetPassword>({
      query: body => ({
        url: 'partner/login/reset',
        method: 'POST',
        body: body,
      }),
    }),
    postMagicLink: build.mutation<TLoginPartnerToken, string>({
      query: search => ({
        url: `partner/login/check${search}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  usePostLoginPartnerMutation,
  usePostResetPasswordMutation,
  usePostMagicLinkMutation,
} = LoginApiPartner;
