import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '@/components/ui/use-toast';

export default function DropdownItemsPartner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    toast({
      variant: 'success',
      title: t('logout_successfully'),
    });
    navigate('/partner/login');
  };

  return (
    <>
      <>
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Link to={'/partner/personal-information'} className="cursor-pointer">
              {t('personal_information')}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to={'/partner/bank-information'} className="cursor-pointer">
              {t('payment_methods')}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to={'/partner/address'} className="cursor-pointer">
              {t('address')}
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </>
      <DropdownMenuSeparator />
      <DropdownMenuItem asChild>
        <Link
          to={''}
          onClick={() => logout()}
          className="cursor-pointer focus:bg-red-100 focus:text-red-900"
        >
          {t('logout')}
        </Link>
      </DropdownMenuItem>
    </>
  );
}
