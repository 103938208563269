import { api } from '../api';

export const bankSellerApi = api.injectEndpoints({
  endpoints: build => ({
    postCashOutSeller: build.mutation<
      void,
      {
        amount: number;
      }
    >({
      query: body => ({
        url: 'seller/cash-out',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
  }),
});

export const { usePostCashOutSellerMutation } = bankSellerApi;
