import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = () => {
  const token = localStorage.getItem('token');
  const tokenType = localStorage.getItem('tokenType');

  if (!token || !tokenType) {
    console.info('ProtectedRoute: no token found');
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
