import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { TInventory } from '@/types/Seller/inventory.ts';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button.tsx';
import { ArrowUp10, Loader2, MoreHorizontal, Tags, Warehouse } from 'lucide-react';
import { format, getUnixTime } from 'date-fns';
import { getLocale } from '@/lib/utils.ts';
import { useState } from 'react';
import { useToast } from '@/components/ui/use-toast.ts';
import { useDeleteInventorySellerMutation } from '@/api/services/Seller/inventory.ts';
import EditItem from '@/components/Seller/edit-item.tsx';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { Link } from 'react-router-dom';

const multiColumnFilterFn: FilterFn<TInventory> = (
  row,
  _columnId,
  filterValue: string,
) => {
  const searchableRowContent = `${row.original.name} ${row.original.sku}`;
  const searchableRowContentLower = searchableRowContent.toLowerCase();
  const filterValueLower = filterValue.toLowerCase();
  return searchableRowContentLower.includes(filterValueLower);
};

export const columnsListing: ColumnDef<TInventory>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      const { t } = useTranslation();

      return <p className="text-black"> {t(`inventory.columns.${column.id}`)}</p>;
    },
    filterFn: multiColumnFilterFn,
    cell: ({ row }) => {
      return (
        <div className="flex md:flex-row flex-col align-middle">
          <div>
            <img
              alt={row.original.name}
              src={row.original.imageUrl}
              className="w-12 rounded m-3"
            />
          </div>
          <div>
            <div className="font-semibold">{row.getValue('name')}</div>
            <div className="text-sm text-muted-foreground">{row.original.sku}</div>
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button variant="ghost" className="text-black cursor-auto">
          <Warehouse className="mr-2 h-4 w-4" />
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    cell: () => {
      const { t } = useTranslation();
      return (
        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
          {t('home')}
        </span>
      );
    },
  },
  {
    accessorKey: 'size',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <ArrowUp10 className="mr-2 h-5 w-5" />
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      return <div>{row.getValue('size')}</div>;
    },
  },
  {
    accessorKey: 'listPrice',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          <Tags className="mr-2 h-5 w-5" />
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('listPrice'));

      // Format the amount as a dollar amount
      const formatted = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount);

      return <div className="text-left font-bold">{formatted}</div>;
    },
  },
  {
    accessorKey: 'updatedAt',
    header: ({ column }) => {
      const { t } = useTranslation();
      return (
        <Button
          variant="ghost"
          className="text-black"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t(`inventory.columns.${column.id}`)}
        </Button>
      );
    },
    sortingFn: (rowA, rowB) => {
      return (
        getUnixTime(new Date(rowA.original.updatedAt)) -
        getUnixTime(new Date(rowB.original.updatedAt))
      );
    },
    cell: ({ row }) => (
      <span>
        {format(row.getValue('updatedAt'), 'dd/MM/yyyy hh:mm:ss', {
          locale: getLocale(),
        })}
      </span>
    ),
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const [isConfirmDelete, setConfirmDelete] = useState(false);
      const [isEditDialog, setEditDialog] = useState(false);
      const { t } = useTranslation();
      const { toast } = useToast();
      const item = row.original;
      const [deleteItem, { isLoading: isLoadingDeleteInventory }] =
        useDeleteInventorySellerMutation();

      return (
        <>
          <EditItem
            isOpenDialog={isEditDialog}
            setOpenDialog={setEditDialog}
            article={row.original}
          />
          <AlertDialog open={isConfirmDelete}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{t('confirm_delete_article')}</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant="outline" onClick={() => setConfirmDelete(false)}>
                    {t('cancel')}
                  </Button>
                </AlertDialogCancel>
                <AlertDialogAction asChild>
                  {isLoadingDeleteInventory ? (
                    <Button
                      disabled
                      className="bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900"
                    >
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t('delete')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900"
                        onClick={() =>
                          deleteItem(item.id)
                            .unwrap()
                            .then(() => {
                              toast({
                                variant: 'success',
                                title: t('deleted_successfully'),
                              });
                              setConfirmDelete(false);
                            })
                            .catch(() => {
                              toast({
                                variant: 'destructive',
                                title: t('an_error_occurred'),
                              });
                              setConfirmDelete(false);
                            })
                        }
                      >
                        {t('delete')}
                      </Button>
                    </>
                  )}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">{t('open_menu')}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t('actions')}</DropdownMenuLabel>
              <Link to={''} onClick={() => setEditDialog(true)}>
                <DropdownMenuItem className="cursor-pointer">
                  <a>{t('edit')}</a>
                </DropdownMenuItem>
              </Link>
              <Link to={''} onClick={() => setConfirmDelete(true)}>
                <DropdownMenuItem className="cursor-pointer focus:bg-red-100 focus:text-red-900">
                  {t('delete')}
                </DropdownMenuItem>
              </Link>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      );
    },
  },
];
