import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TRegisterFormStep2, ZRegisterFormStep2Schema } from '@/types/Partner/onboarding';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import { useEffect } from 'react';
import { setRegisterPartnerStep2 } from '@/api/services/app';
import { useDispatch } from 'react-redux';

type PartnerSignFormProps = React.HTMLAttributes<HTMLDivElement>;

export function RegisterFormStep2({ className, ...props }: PartnerSignFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();

  const form = useForm<TRegisterFormStep2>({
    resolver: zodResolver(ZRegisterFormStep2Schema),
    defaultValues: {
      entityStreet: '',
      entityCity: '',
      entityZipcode: '',
      entityCountry: undefined,
      proofOfAddress: '',
    },
  });
  const { setValue } = form;

  const onChangeFile = (file: File, type: keyof TRegisterFormStep2) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  const onSubmit = (values: TRegisterFormStep2) => {
    sessionStorage.setItem('registerPartnerStep2', JSON.stringify(values));
    dispatch(setRegisterPartnerStep2(values));
    navigate(`/partner/register/${token}/step3`);
  };

  useEffect(() => {
    const data = sessionStorage.getItem('registerPartnerStep2');
    if (data) {
      try {
        const dataParse = JSON.parse(data) as TRegisterFormStep2;
        form.setValue('entityStreet', dataParse.entityStreet);
        form.setValue('entityCity', dataParse.entityCity);
        form.setValue('entityZipcode', dataParse.entityZipcode);
        form.setValue('entityCountry', dataParse.entityCountry);
        form.setValue('proofOfAddress', dataParse.proofOfAddress);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="entityStreet"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('address')}</FormLabel>
                      <FormControl>
                        <AddressAutofill accessToken={`${process.env.MAPBOX_API_KEY}`}>
                          <Input autoComplete="address-line1" {...field} />
                        </AddressAutofill>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="entityCity"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('city')}</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="address-level2" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="entityZipcode"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('postcode')}</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="postal-code" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="entityCountry"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('country')}</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="country-name" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="proofOfAddress"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('proof_of_address')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'proofOfAddress');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit">{t('next')}</Button>
            <Button
              variant="outline"
              onClick={() => navigate(`/partner/register/${token}/step1`)}
            >
              {t('previous')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
