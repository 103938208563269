import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.API_URL || 'http://localhost:3000/api',
  mode: 'cors',
  prepareHeaders: headers => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithSecurity: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401 &&
    ['postLogin'].includes(api.endpoint)
  ) {
    return result;
  } else if (
    result.error &&
    result.error.status === 401 &&
    !['postLoginPartner'].includes(api.endpoint)
  ) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    window.location.href = '/?error=unauthorized';
  }
  return result;
};

const baseQueryWithSecurityWithRetry = retry(baseQueryWithSecurity, { maxRetries: 0 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  baseQuery: baseQueryWithSecurityWithRetry,
  endpoints: () => ({}),
  tagTypes: [
    'Address',
    'Me',
    'SellerInventory',
    'Request',
    'OffersSeller',
    'OffersPartner',
    'RequestSeller',
    'OffersKPISeller',
    'InventoryKPISeller',
    'OffersKPIPartner',
  ],
});
