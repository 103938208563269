import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';
import { PasswordField } from '@/components/ui/password-field';
import { TRegisterFormStep1, ZRegisterFormStep1Schema } from '@/types/Partner/onboarding';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRegisterPartnerStep1 } from '@/api/services/app';
import { usePostCheckEmailMutation } from '@/api/services/Partner/onboarding';
import { useToast } from '@/components/ui/use-toast';

type PartnerSignFormProps = React.HTMLAttributes<HTMLDivElement>;

export function RegisterFormStep1({ className, ...props }: PartnerSignFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [postCheckEmail] = usePostCheckEmailMutation();
  const { toast } = useToast();

  const form = useForm<TRegisterFormStep1>({
    resolver: zodResolver(ZRegisterFormStep1Schema),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = (values: TRegisterFormStep1) => {
    console.info(values);
    sessionStorage.setItem('registerPartnerStep1', JSON.stringify(values));
    dispatch(setRegisterPartnerStep1(values));
    postCheckEmail({ email: values.email })
      .unwrap()
      .then(() => {
        navigate(`/partner/register/${token}/step2`);
      })
      .catch(err => {
        toast({
          variant: 'destructive',
          title: t('email_already_exists'),
        });
        console.error(err);
      });
  };

  useEffect(() => {
    const data = sessionStorage.getItem('registerPartnerStep1');
    if (data) {
      try {
        const dataParse = JSON.parse(data) as TRegisterFormStep1;
        form.setValue('email', dataParse.email);
        form.setValue('password', dataParse.password);
        form.setValue('passwordConfirmation', dataParse.passwordConfirmation);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('email')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('password')}</FormLabel>
                      <FormControl>
                        <PasswordField {...field} />
                      </FormControl>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="passwordConfirmation"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('confirm_password')}</FormLabel>
                      <FormControl>
                        <PasswordField {...field} />
                      </FormControl>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit">{t('next')}</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
