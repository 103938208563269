import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TTrackingForm, ZTrackingForm } from '@/types/Seller/tracking';
import { useTranslation } from 'react-i18next';
import { usePostTrackingSellerMutation } from '@/api/services/Seller/tracking';
import { useToast } from '@/components/ui/use-toast';
import { TOffers } from '@/types/Seller/offers';
import { Loader2 } from 'lucide-react';

export default function TrackingForm({
  offer,
  setOpenDialog,
}: {
  offer: TOffers;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const [postTracking, { isLoading: isLoadingTranking }] =
    usePostTrackingSellerMutation();
  const { toast } = useToast();

  const form = useForm<TTrackingForm>({
    resolver: zodResolver(ZTrackingForm),
    defaultValues: {
      trackingUrl: '',
    },
  });

  const onSubmit = (values: TTrackingForm) => {
    postTracking({
      id: offer.id,
      body: {
        trackingUrl: values.trackingUrl,
      },
    })
      .unwrap()
      .then(() => {
        toast({
          variant: 'success',
          title: t('shipping_successfully'),
        });
        setOpenDialog(false);
      })
      .catch(err => {
        console.error(err);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="trackingUrl"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('tracking_url')}</FormLabel>
                      <FormControl>
                        <Input type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            {isLoadingTranking ? (
              <Button disabled>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t('validate')}
              </Button>
            ) : (
              <>
                <Button type="submit">{t('validate')}</Button>
              </>
            )}
          </div>
        </form>
      </Form>
      <Button
        type="submit"
        variant="outline"
        className="w-full mt-2"
        onClick={() => setOpenDialog(false)}
      >
        {t('cancel')}
      </Button>
    </>
  );
}
