import { z } from 'zod';

let password = '';

export interface TCheckEmailBody {
  email: string;
}

export interface TokenValidity {
  status: ONBOARDING_STATUS_PARTNER;
}

export interface TCheckStatus {
  route: string;
  message: string;
  type: 'default' | 'success' | 'destructive' | null | undefined;
}

export enum ONBOARDING_STATUS_PARTNER {
  USED = 'USED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export const ZRegisterFormStep1Schema = z.object({
  email: z.string().min(1, {
    message: 'required_field',
  }),
  password: z
    .string()
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
      message: 'register.password_invalid',
    })
    .refine(value => {
      password = value;
      return true;
    }),
  passwordConfirmation: z
    .string()
    .min(6, { message: 'required_field' })
    .refine(value => value === password, 'password_not_corresponding'),
});

export const ZRegisterFormStep2Schema = z.object({
  entityStreet: z.string().min(1, {
    message: 'required_field',
  }),
  entityZipcode: z.string().min(1, {
    message: 'required_field',
  }),
  entityCity: z.string().min(1, {
    message: 'required_field',
  }),
  entityCountry: z.string().min(1, {
    message: 'required_field',
  }),
  proofOfAddress: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZRegisterFormStep3Schema = z.object({
  businessStatus: z.string().min(1, {
    message: 'required_field',
  }),
  entityName: z.string().min(1, {
    message: 'required_field',
  }),
  registrationNumber: z
    .string()
    .min(9, {
      message: 'min_9',
    })
    .max(14, {
      message: 'min_9',
    }),
  businessType: z.coerce.number().min(1, {
    message: 'required_field',
  }),
  proofOfRegistration: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZRegisterFormStep4Schema = z.object({
  holderName: z.string().min(1, {
    message: 'required_field',
  }),
  iban: z
    .string()
    .regex(
      /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/,
      {
        message: 'iban_invalid',
      },
    )
    .refine(value => {
      password = value;
      return true;
    }),
  bic: z
    .string()
    .regex(/[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/, {
      message: 'bic_invalid',
    })
    .refine(value => {
      password = value;
      return true;
    }),
  proofOfBankAccount: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZRegisterFormStep5Schema = z.object({
  representativeFirstName: z.string().min(1, {
    message: 'required_field',
  }),
  representativeLastName: z.string().min(1, {
    message: 'required_field',
  }),
  representativeBirthDate: z
    .date({
      required_error: 'required_field',
    })
    .or(
      z.string().min(1, {
        message: 'required_field',
      }),
    ),
  representativeEmail: z.string().optional(),
  representativeNationality: z.string().min(1, {
    message: 'required_field',
  }),
  accountingReport: z.string().min(1, {
    message: 'required_field',
  }),
  backOfIdentityCard: z.string().min(1, {
    message: 'required_field',
  }),
  frontOfIdentityCard: z.string().min(1, {
    message: 'required_field',
  }),
});

export const ZRegistrationFormSchema = z
  .object({
    token: z.string(),
  })
  .merge(ZRegisterFormStep1Schema)
  .merge(ZRegisterFormStep2Schema)
  .merge(ZRegisterFormStep3Schema)
  .merge(ZRegisterFormStep4Schema)
  .merge(ZRegisterFormStep5Schema);

export const ZPersonalInformationFormSchema = z.object({
  firstName: z.string().min(1, {
    message: 'required_field',
  }),
  lastName: z.string().min(2, {
    message: 'required_field',
  }),
  email: z.string().email({
    message: 'invalid_field',
  }),
  fakePassword: z.string().optional(),
  password: z
    .string()
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
      message: 'register.password_invalid',
    })
    .refine(value => {
      password = value;
      return true;
    }),
});

export const ZUpdatePasswordFormSchema = z.object({
  password: z
    .string()
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
      message: 'register.password_invalid',
    })
    .refine(value => {
      password = value;
      return true;
    }),
});

export type TRegistrationForm = z.infer<typeof ZRegistrationFormSchema>;
export type TRegisterFormStep1 = z.infer<typeof ZRegisterFormStep1Schema>;
export type TRegisterFormStep2 = z.infer<typeof ZRegisterFormStep2Schema>;
export type TRegisterFormStep3 = z.infer<typeof ZRegisterFormStep3Schema>;
export type TRegisterFormStep4 = z.infer<typeof ZRegisterFormStep4Schema>;
export type TRegisterFormStep5 = z.infer<typeof ZRegisterFormStep5Schema>;
export type TPersonalInformationForm = z.infer<typeof ZPersonalInformationFormSchema>;
export type TUpdatePasswordForm = z.infer<typeof ZUpdatePasswordFormSchema>;
