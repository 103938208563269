import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/api';
import app from './services/app';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: app.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([api.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
