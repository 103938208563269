import { ThemeProvider } from './contexts/ThemeContext';
import Routes from '@/Router';

export default function App() {
  return (
    <ThemeProvider>
      <Routes />
    </ThemeProvider>
  );
}
