import { api } from '../api';
import { TInventory, TItem } from '@/types/Seller/inventory';

export const requestPartnerApi = api.injectEndpoints({
  endpoints: build => ({
    getRequestPartner: build.query<TInventory[], void>({
      query: () => ({ url: 'partner/request' }),
      providesTags: ['Request'],
    }),
    postRequestPartner: build.mutation<[], TItem>({
      query: body => ({
        url: 'partner/request',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Request'],
    }),
    deleteRequestPartner: build.mutation<[], number>({
      query: id => ({
        url: `partner/request/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Request'],
    }),
  }),
});

export const {
  useGetRequestPartnerQuery,
  usePostRequestPartnerMutation,
  useDeleteRequestPartnerMutation,
} = requestPartnerApi;
