import { api } from '../api';
import { TItem } from '@/types/Seller/inventory';
import { TOffersStatisticsPartner, TRejectOffer } from '@/types/Partner/offers';
import { TCounterOfferPost, TOffers } from '@/types/Seller/offers';

export const offersPartnerApi = api.injectEndpoints({
  endpoints: build => ({
    getOffersPartner: build.query<TOffers[], void>({
      query: () => ({ url: 'partner/offers' }),
      providesTags: ['OffersPartner'],
    }),
    postOffersPartner: build.mutation<[], TItem>({
      query: body => ({
        url: 'partner/offers',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OffersPartner', 'OffersKPIPartner'],
    }),
    postRefuseOfferPartner: build.mutation<void, number>({
      query: id => ({
        url: `partner/offer/${id}/refuse`,
        method: 'POST',
      }),
      invalidatesTags: ['OffersPartner', 'OffersKPIPartner'],
    }),
    postAcceptOfferPartner: build.mutation<
      {
        redirect_url: '';
      },
      {
        id: number;
        body: {
          paymentMethod: 'card' | 'balance';
        };
      }
    >({
      query: params => ({
        url: `partner/offer/${params.id}/accept`,
        method: 'POST',
        body: params.body,
      }),
      invalidatesTags: ['OffersPartner', 'Me', 'OffersKPIPartner'],
    }),
    postNewCounterOfferPartner: build.mutation<void, TCounterOfferPost>({
      query: body => ({
        url: `partner/offer/${body.id}/proposals`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OffersPartner', 'OffersKPIPartner'],
    }),
    postFinalizeOfferPartner: build.mutation<void, number>({
      query: id => ({
        url: `partner/offer/${id}/finalize`,
        method: 'POST',
      }),
      invalidatesTags: ['OffersPartner', 'OffersKPIPartner'],
    }),
    postRejectOfferPartner: build.mutation<void, TRejectOffer>({
      query: param => ({
        url: `partner/offer/${param.id}/reject`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: ['OffersPartner', 'OffersKPIPartner'],
    }),
    getOffersStatisticsPartner: build.query<TOffersStatisticsPartner, void>({
      query: () => ({
        url: `partner/offers-statistics`,
        method: 'GET',
      }),
      providesTags: ['OffersKPIPartner'],
    }),
  }),
});

export const {
  useGetOffersPartnerQuery,
  usePostRefuseOfferPartnerMutation,
  usePostAcceptOfferPartnerMutation,
  usePostNewCounterOfferPartnerMutation,
  usePostFinalizeOfferPartnerMutation,
  usePostRejectOfferPartnerMutation,
  useGetOffersStatisticsPartnerQuery,
} = offersPartnerApi;
