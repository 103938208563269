import { Wallet } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { useGetMePartnerQuery } from '@/api/services/Partner/me';
import { Skeleton } from '@/components/ui/skeleton';
import CashIn from '@/components/Partner/cash-in';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function BankSoldPartner() {
  const [openCashIn, setOpenOpenCashIn] = useState(false);

  const { t } = useTranslation();

  const { data: me } = useGetMePartnerQuery();
  return (
    <div className="flex flex-col space-y-3 cursor-pointer">
      <CashIn isOpenDialog={openCashIn} setOpenDialog={setOpenOpenCashIn} />
      <div
        className={cn('ml-3 gap-4 mb-4 flex items-center font-bold')}
        onClick={() => setOpenOpenCashIn(true)}
      >
        <div>
          <Button variant="secondary" size="icon">
            <Wallet className="h-5 w-5" />
          </Button>
        </div>
        <div className="flex flex-row">
          <p>
            {t('total_balance')} :{' '}
            {me ? `${me.balance}€` : <Skeleton className="ml-2 w-[50px]" />}
          </p>
        </div>
      </div>
    </div>
  );
}
