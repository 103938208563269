export default function CGUV() {
  return (
    <>
      <>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            La plateforme SCALE (le Site) est gérée et hébergée par Monsieur Léo
            MARILLIER, dont le siège social est situé 10 Rue Béranger – 77300
            FONTAINEBLEAU, immatriculée sous le numéro 893 773 648 au Registre du Commerce
            et des Sociétés de MELUN.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Avant de s’inscrire sur le Site, chaque Utilisateur doit lire les présentes
            Conditions Générales d’Utilisation et de Vente (les CGUV), lesquelles
            constituent un accord juridiquement contraignant entre vous et SCALE
            concernant votre accès et votre utilisation du Site et s’engage à les
            respecter. Pour confirmer votre accord et créer votre compte SCALE, veuillez
            accepter ces CGUV lorsque vous êtes invité(e) à le faire au cours du processus
            d’inscription. Chaque Utilisateur doit également lire la&nbsp;
          </span>
          <a href="https://vinted.fr/privacy-policy">
            <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
              Politique de confidentialité
            </span>
          </a>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>.</span>
        </p>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les termes commençant par une majuscule sont définis à l’article 1 des
            présentes CGUV.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 1 – DÉFINITIONS
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Lorsque des termes commençant par une majuscule sont employés dans les
            présentes CGUV, ils sont définis dans le présent article. Veuillez-vous
            référer à la présente section « Définitions » chaque fois qu’un terme
            commençant par une majuscule est employé.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Acheteur – désigne tout Utilisateur qui achète ou souhaite acheter un ou
                plusieurs Articles sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Articles – désignent les produits/articles que les Utilisateurs ajoutent
                au Catalogue afin de les vendre ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Bouton Acheter maintenant / Acheter – désigne le bouton affiché sur la
                page de chaque Article, qui permet aux Acheteurs d’exprimer leur volonté
                de commencer à commander un Article sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Catalogue – désigne le catalogue électronique des Articles que les
                Vendeurs ont référencés pour être vendus via le Site. Le Catalogue
                comporte des sections différentes pour chaque catégorie d’Articles ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Compte Utilisateur ou Compte – désigne le compte de l’Utilisateur créé
                lors de son inscription sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Conditions Générales d’Utilisation et de Vente (CGUV) – désignent l’accord
                juridiquement contraignant entre SCALE et l’Utilisateur, tel que défini au
                premier paragraphe des présentes Conditions Générales d’Utilisation et de
                Vente ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Consignation – désigne un moyen d’achat qui conditionne le paiement du
                prix au Vendeur à la revente par l’Acheteur de l’Article à un tiers (le
                client final) ;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Messages Privés – désignent la communication par échange de messages
                privés entre Utilisateurs via le système de messagerie du Site, lorsque
                les messages ne sont pas affichés publiquement sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Paiement – désigne le processus de paiement à la disposition des Acheteurs
                pour acheter des Articles sur le Site. Le processus de Paiement : (i) est
                initié dès que l’Acheteur clique sur le bouton Acheter, (ii) implique que
                l’Acheteur choisisse un mode de paiement et renseigne une adresse de
                livraison et (iii) est finalisé lorsque l’Acheteur clique sur le bouton
                Payer sur la page de paiement ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Politique de Confidentialité – désigne le document qui définit la manière
                dont SCALE collecte, utilise, traite, stocke et transfère les données à
                caractère personnel ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Porte-monnaie électronique (Balance) – service financier fourni par le
                prestataire de services de paiement qui permet aux Utilisateurs de créer
                des portemonnaies électroniques sur le Site. La solution de porte-monnaie
                électronique permet aux Utilisateurs de recevoir des fonds pour des
                Articles vendus sur le Site, de stocker les fonds, de les verser sur le
                compte bancaire de l’Utilisateur, d’acheter des Services Supplémentaires
                ou d’acheter des Articles à d’autres Utilisateurs sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp; &nbsp;Prix Total – désigne le montant total dû au titre
                d’une Transaction, incluant le prix des Articles fixés par le Vendeur et
                les frais de port. Si un Vendeur/Consignateur est amené à demander un
                retour pour un Produit qui était en consigne ou qui a été refusé par
                l’Acheteur/Consignataire, le Vendeur/Consignateur paiera une somme de 10 à
                15 € à l’Acheteur/Consignataire pour que le Produit acheté ou consigné lui
                soit renvoyé.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Site – désigne le site internet SCALE, les applications et tout autre
                moyen d’accéder à la plateforme SCALE. Le Site comprend les Services et
                tous les contenus, outils, fonctions et fonctionnalités proposés sur ou
                via la plateforme ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Séquestre – désigne le compte séquestre géré par le prestataire de
                services de paiement, sur lequel le montant du Prix Total est conservé
                jusqu’à ce que la Transaction soit finalisée et que le paiement soit
                effectué ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Services – désigne l’ensemble des services proposés par le Site, y compris
                les Services d’Hébergement et les Services de Paiement chacun d’entre eux
                étant fourni dans le but d’assurer une expérience sécurisée et fiable du
                Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Services d’hébergement – désignent les services d’hébergement proposés par
                SCALE sur le Site ou par l’intermédiaire de celui-ci, permettant aux
                Utilisateurs d’ajouter des Articles au Catalogue, de rechercher et de
                consulter les offres à la vente sur le Site et de communiquer en privé
                et/ou en public, notamment en utilisant le Forum ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Service de Paiement – désigne le système de paiement en ligne permettant
                aux Utilisateurs de payer des Articles et des Services sur le Site, le
                service de paiement étant fourni par le prestataire de services de
                paiement ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Transaction – désigne toute transaction effectuée entre Utilisateurs en
                utilisant le bouton
              </span>
            </p>
          </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            « Acheter » et aux termes de laquelle un contrat d’achat pour l’Article
            concerné est conclu entre les Utilisateurs ;
          </span>
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Utilisateur – désigne toute personne qui a créé un compte sur le Site et a
                accepté les présentes Conditions Générales ;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Vendeur – désigne tout Utilisateur qui référence un ou plusieurs
                Article(s) sur le Catalogue du Site correspondant. Aux fins de la
                prestation de services de paiement, le Vendeur est une personne qui a
                vendu au moins un Article ;
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 2 – ACCES AU SITE ET AUX SERVICES
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Acceptation des CGUV
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les présentes CGUV définissent les conditions générales en vertu desquelles
            vous pouvez utiliser le Site. Si vous acceptez les présentes CGUV, vous
            pourrez utiliser notre Site en tant qu’Utilisateur et aurez la possibilité
            d’agir en tant qu’Acheteur et Vendeur d’Articles sur le Site. Si vous
            n’acceptez pas les présentes CGUV, vous n’êtes pas autorisé(e) à utiliser le
            Site.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Services
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE propose des Services d’hébergement en tant qu’intermédiaire entre les
            Acheteurs et les Vendeurs. SCALE est entièrement responsable des Prestations
            qu’elle fournit, dans la limite de ses engagements et dans les conditions
            prévues par les présentes CGUV. SCALE n’est partie à aucune Transaction entre
            les Utilisateurs. SCALE n’achète ni ne vend les Articles figurant sur le Site.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Utilisateurs non autorisés
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le Site n’est pas destiné à être utilisé par des personnes âgées de moins de
            18 ans. Si vous êtes parent ou tuteur d’une personne à charge âgée de moins de
            18 ans, vous pouvez créer un Compte et accepter les présentes Conditions
            Générales au nom de ce mineur, mais vous assumerez l’entière responsabilité de
            l’utilisation du Site par le mineur et reconnaissez que le Site est destiné
            aux personnes âgées de 18 ans au moins.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Création d’un Compte
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Pour acheter ou vendre des Articles sur le Site et pour utiliser les Services,
            vous devez d’abord faire partie de la communauté privée « One CookLab »
            disponible sur la plateforme « Discord » (lien&nbsp;
          </span>
          <a href="https://www.instagram.com/onecooklab">
            <u>
              <span
                style={{
                  color: '#0000ff',
                  backgroundColor: '#ffffff',
                  fontSize: '12pt',
                }}
              >
                https://www.instagram.com/onecooklab
              </span>
            </u>
          </a>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>/).</span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Seuls les membres de la communauté ont accès au Site en tant qu’Utilisateurs.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Lors de votre inscription sur le Site, vous vous engagez à fournir des
            informations véridiques, exactes, à jour et complètes. Il est important que
            vous conserviez et mettiez rapidement à jour les informations relatives à
            votre Compte afin de tenir ces informations à jour et complètes à tout moment.
            Au cours du processus d’inscription, les Utilisateurs sont invités à fournir
            une adresse e-mail, un nom d’utilisateur (un pseudonyme à utiliser sur le
            Site) et un mot de passe pour permettre au Site de vous identifier à chaque
            fois que vous accéderez au Site. Les finalités et la manière dont nous
            utilisons ces données sont décrites dans la Politique de Confidentialité. Afin
            de protéger votre Compte Utilisateur et d’assurer la sécurité des autres
            Utilisateurs,
          </span>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            <br />
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE pourra, si nécessaire, vérifier les informations figurant dans votre
            Compte Utilisateur ou vous demander de répondre à certaines questions, de
            fournir certaines informations ou de participer à des procédures de
            vérification. L’objet de ces procédures de sécurité est de vérifier que c’est
            bien vous qui accédez à votre Compte Utilisateur et/ou effectuez des
            transactions à partir de celui-ci. Ces procédures seront raisonnables et
            proportionnées aux objectifs de sécurité poursuivis. Dans le cadre des
            procédures, SCALE peut vérifier le numéro de téléphone et l’adresse
            électronique, ainsi que les coordonnées de la carte de crédit, de la carte de
            débit ou de tout autre moyen de paiement de l’Utilisateur et de son compte
            bancaire.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            En l’espèce, SCALE demandera à tout Utilisateur de fournir une pièce
            justificative de son identité en cours de validité (passeport, carte nationale
            d’identité recto/verso) et un RIB de votre compte bancaire.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Conditions générales de tiers
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Certaines fonctionnalités du Site utilisent des outils et des services fournis
            par des tiers, qui peuvent être régis par des conditions générales distinctes.
            Par exemple, pour utiliser le Service de Paiement Intégré, vous devrez
            accepter les conditions générales du prestataire de services de paiement
            (veuillez consulter la Section 4.1 pour plus d’informations sur la façon dont
            les utilisateurs sont invités à accepter les conditions générales du
            prestataire de services de paiement). Lorsque les outils et services de tiers
            sont régis par des conditions générales distinctes, le Site affichera un lien
            hypertexte renvoyant vers les conditions générales applicables. Celles-ci
            seront mises à votre disposition, dans le cadre des services fournis par les
            tiers concernés, dans un format permettant de les reproduire et de les
            stocker. Vous devrez les accepter séparément si vous souhaitez utiliser les
            services proposés par ces tiers.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Modifications des présentes CGUV
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Toute modification des présentes Conditions Générales sera soumise à votre
            acceptation préalable. Si l’Utilisateur n’accepte pas les nouvelles CGUV dans
            les 30 jours suivant la date de notification d’une modification, SCALE se
            réserve le droit de restreindre la capacité de l’Utilisateur à utiliser le
            Site jusqu’à ce que qu’il ait accepté les CGUV modifiées.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Si une ou plusieurs stipulations des CGUV sont tenues pour non valides ou
            déclarées comme telles en application d’une loi, d’un règlement ou à la suite
            d’une décision définitive d’une juridiction compétente, elles seront réputées
            non écrites et les autres stipulations demeureront en vigueur.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 3 – AJOUT D’ARTICLES SUR LE SITE
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Articles autorisés
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Seuls les Articles qui remplissent toutes les conditions suivantes peuvent
            être ajoutés sur le Site :
          </span>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            <br />
          </span>
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Le Vendeur doit avoir le droit de transférer la propriété de l’Article ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Le Vendeur ne doit pas proposer à la revente des Articles achetés en vrac
                ou des Articles vendus en tant que produits en livraison directe (ou
                dropshipping) ayant une faible valeur de revente ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                La vente, l’utilisation ou la possession de l’Article ne doit pas
                enfreindre les droits de tiers (notamment les droits de propriété
                intellectuelle), ni enfreindre une loi ou réglementation applicable au
                niveau local, national ou international. L’Article doit être sûr et ne pas
                être une contrefaçon ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Article doit respecter toutes les exigences couramment appliquées sur le
                marché, sauf accord contraire entre l’Acheteur et le Vendeur ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Article doit être neuf et n’avoir jamais été utilisé ;&nbsp;
              </span>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  3.2. Ajout d’un Article
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Pour ajouter un Article au Catalogue le Vendeur doit remplir le questionnaire,
            y compris sélectionner la catégorie qui correspond le mieux à l’Article et
            sélectionner la photo correspondante à l’Article figurant dans la base de
            données proposée par SCALE.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Offre à la vente
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le fait qu’un Vendeur ajoute un Article dans le Catalogue et que l’Article
            ainsi ajouté soit publié sur le Site constitue une offre à la vente de
            l’Article concerné par le Vendeur. Cette offre est susceptible d’être acceptée
            par un Acheteur.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Modification d’une annonce
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le Vendeur qui a ajouté l’Article peut le retirer ou en modifier le prix à
            tout moment, avant de conclure un contrat avec un Utilisateur pour la vente de
            l’Article considéré.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Retrait des contenus
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE, après avoir été dûment notifiée conformément à l’article 7.4 ci-dessous
            ou par les Autorités compétentes, supprimera rapidement tout contenu illicite.
            SCALE est également susceptible d’utiliser des logiciels et algorithmes
            automatisés afin de détecter et de supprimer immédiatement et sans préavis,
            tout Article ou Contenu qui :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                serait manifestement illicite ou contraire à la réglementation applicable
                (par exemple, une contrefaçon);
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                serait contraire aux bonnes mœurs ou à la sécurité publique ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                serait ajouté ou publié par un Vendeur dans le cadre d’une activité
                commerciale professionnelle, en violation des Règles du Catalogue; ou
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                de quelque autre façon, enfreindrait les présentes CGUV.
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            L’Utilisateur concerné pourra faire appel de la décision de SCALE de la façon
            décrite à l’article
          </span>
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            9.6 ci-après.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            L’utilisation par SCALE de ces logiciels automatisés ne saurait être
            interprétée comme un engagement de surveillance ou une obligation de
            rechercher les activités et/ou les Contenus illicites sur le Site et, dans la
            mesure permise par la loi applicable, ne saurait engager la responsabilité de
            CGUV.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 4 – PROCESSUS DE VENTE DES ARTICLES
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Du côté du Vendeur
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Afin de procéder à la vente d’un Article, le Vendeur devra remplir les étapes
            suivantes :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Mettre sur le Site les renseignements concernant l’Article qu’il souhaite
                vendre (sélection des photos correspondant au modèle dans la base de
                données proposée par SCALE, pointure, etc…) ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lorsqu’un Acheteur est intéressé par l’Article mis en vente et qu’il
                envoie son offre au Vendeur, ce dernier reçoit une notification sur la
                plateforme ainsi que par mail ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Se rendre sur l’espace de l’offre et l’accepter en deux clics ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Procéder à l’envoi du colis en suivant la procédure décrite à l’article
                6.1 ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Le Prix de la vente sera transféré sur le Porte-monnaie électronique du
                Vendeur une fois que l’Acheteur aura réceptionné le colis et confirmé sur
                le Site la conformité de l’Article reçu.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Du côté de l’Acheteur
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Afin de procéder à l’achat ou à la consignation d’un Article, l’Acheteur
            professionnel devra remplir les étapes suivantes :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Créer une offre d’achat ou de Consignation ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Sélectionner le modèle de l’Article ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Envoyer une offre à tous les Vendeurs ayant le produit recherché ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lorsqu’un Vendeur accepte l’offre d’achat ou de consignation de
                l’Acheteur, celui-ci reçoit une notification sur la plateforme ainsi que
                par mail ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Réceptionner et confirmer la conformité de l’Article par une double
                confirmation sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Procéder au paiement sur le Porte-monnaie électronique du Site.
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ol>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 5 – PORTE-MONNAIE ÉLECTRONIQUE (BALANCE)
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Paramétrage du Porte-monnaie électronique
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le Porte-monnaie électronique est un service de paiement fourni par le
            prestataire de services de paiement. Lorsqu’un Utilisateur crée un
            porte-monnaie électronique, il conclut un contrat directement avec le
            prestataire de services de paiement et accepte les conditions générales du
            prestataire de services de paiement. Il est demandé aux Utilisateurs de le
            confirmer lors de la création du porte-monnaie électronique. Si le Vendeur ne
            le fait pas dans un délai de trois (3) jours, la Transaction de vente sera
            annulée. Avant que la première Transaction de vente n’ait lieu, les Vendeurs
            peuvent également créer le porte-monnaie électronique de leur propre
            initiative via la rubrique paramètres du compte (en sélectionnant Balance,
            puis en cliquant sur Activer la Balance) et en fournissant les données
            requises.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Vérifications KYC
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Une fois que le porte-monnaie électronique est créé, les Utilisateurs sont
            soumis aux procédures réglementaires mises en œuvre par le prestataire de
            services de paiement, telles que les vérifications d’identité et les autres
            exigences en matière de Connaissance du client (ou KYC), lorsque les seuils
            financiers respectifs sont atteints. Ces seuils financiers sont fixés par le
            prestataire de services de paiement et dépendent du montant total des
            paiements effectués par l’Utilisateur. Le prestataire de services de paiement
            peut également procéder à des vérifications KYC concernant l’Utilisateur qu’il
            soupçonne de se livrer à des pratiques potentiellement frauduleuses ou
            suspectes sans atteindre les seuils fixés. Aux fins du contrôle d’identité,
            l’Utilisateur peut être invité à fournir des informations supplémentaires
            (telles qu’une copie de son passeport, de sa carte d’identité ou de son permis
            de conduire) à la demande du prestataire de services de paiement. Si un
            Utilisateur ne fournit pas les documents requis par le prestataire de services
            de paiement, son porte-monnaie électronique pourra être suspendu et
            l’Utilisateur ne pourra pas transférer de fonds tant que son identité n’aura
            pas été confirmée. Le prestataire de services de paiement peut également
            suspendre la capacité de l’Utilisateur à effectuer des transactions à partir
            de son porte-monnaie électronique. Si, en revanche, un Utilisateur a commis un
            acte illégal, par exemple en téléchargeant un document frauduleux ou en
            prenant toute autre mesure illégale visée à l’article 10.3 conduisant le
            prestataire de services de paiement à considérer le comportement de cet
            Utilisateur comme frauduleux, l’Utilisateur sera considéré comme ne respectant
            pas les exigences de KYC et ne sera pas en mesure de verser les fonds ou d’en
            disposer.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Transfert des fonds détenus sur un porte-monnaie électronique
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les Utilisateurs peuvent transférer des fonds de leur porte-monnaie
            électronique vers leur compte bancaire. Ce transfert sera effectué par le
            prestataire de services de paiement. Pour activer cette option, l’Utilisateur
            devra d’abord accéder aux paramètres de son compte Utilisateur et renseigner
            son numéro de compte bancaire, son nom complet et son adresse de facturation.
            L’Utilisateur peut effectuer un versement sur un compte bancaire en euros
            ouvert
          </span>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            <br />
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            dans une banque de l’Union européenne/Espace économique européen. Les
            versements sur des comptes bancaires dans d’autres devises et/ou en dehors de
            l’Union européenne/Espace économique européen ne sont pas disponibles. Si
            l’Utilisateur atteint les seuils de KYC visés à l’article 9.2 ci-avant ou si
            le prestataire de services de paiement soupçonne que l’Utilisateur se livre à
            des activités potentiellement suspectes ou frauduleuses, l’Utilisateur ne sera
            pas en mesure de transférer des fonds tant qu’il n’aura pas satisfait aux
            exigences de KYC applicables.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 6 – EXPÉDITION DES ARTICLES ET INDEMNISATION DU VENDEUR
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Option d’expédition
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE ne fournit pas de services d’envoi.
          </span>
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les envois se feront par le site LA POSTE. Il est demandé au Vendeur, une fois
            qu’il a accepté l’offre de l’Acheteur, d’effectuer les étapes suivantes :
          </span>
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Cliquer sur lien hypertexte renvoyant sur le site LA POSTE ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Impression de l’étiquette de vente à mettre dans le colis ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Achat d’un label sur le site de « LA POSTE » en suivant le lien qui se
                trouvera sur le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Indiquer le numéro de suivi du colis dans l’espace de transaction pour
                compléter cette dernière.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les Vendeurs et les Acheteurs peuvent accéder aux conditions générales de LA
            POSTE ici pour les acheteurs et ici pour les vendeurs.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le Vendeur pourra quant à lui prétendre à une indemnisation si l’Article est
            perdu ou endommagé, dans les limites d’indemnisation fixées par chaque
            prestataire de transport dans ses conditions générales. L’indemnisation du
            Vendeur sera prise en charge par le prestataire de transport directement et le
            Vendeur devra suivre les procédures du prestataire de transport.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Paiement des frais de port
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les frais de port sont payés par l’Acheteur, en sus du prix de l’Article. Les
            frais de port applicables seront clairement indiqués à l’Acheteur avant tout
            achat. Ils peuvent varier d’une commande à l’autre, en fonction de facteurs
            tels que les dimensions du colis, l’itinéraire de transport, et les promotions
            éventuelles.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Engagement d’expédition
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le Vendeur devra expédier l’Article à l’Acheteur dans un délai de trois (3)
            jours ouvrés. Si le Vendeur n’expédie pas l’Article dans ce délai, SCALE
            pourra annuler la Transaction et ordonner au prestataire de services de
            paiement de verser le Prix Total placé sous Séquestre à l’Acheteur. Le Vendeur
            est responsable de l’emballage adéquat du ou des Article(s).
          </span>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            <br />
          </span>
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 7 – INTERACTION ET MESSAGES SUR LE SITE
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Une fois qu’une Transaction est finalisée, l’Acheteur peut publier un
            commentaire sur le Vendeur sur le Site, et inversement. Ni les Utilisateurs,
            ni SCALE ne perçoivent la moindre rémunération en contrepartie de ces
            commentaires.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Le commentaire d’un Utilisateur sur un autre Utilisateur doit toujours être
            juste et honnête. Les mensonges et les insultes sont interdits.
          </span>
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE ne vérifie pas les commentaires avant leur publication sur le Site par
            les Utilisateurs. Si un commentaire publié sur le Site est inapproprié ou
            abusif ou, de quelque autre façon, ne respecte pas les présentes Conditions
            Générales, les Utilisateurs peuvent le signaler à SCALE conformément à la
            procédure de signalement décrite à l’article 8.5 ci-après. Sur demande d’un
            Utilisateur, SCALE supprimera les commentaires conformément aux présentes
            Conditions Générales et aux lois applicables.
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            En outre, SCALE pourra bloquer le compte de tout Utilisateur qui publierait un
            commentaire non conforme aux présentes Conditions Générales, comme indiqué
            l’article 10.1. Seuls les Utilisateurs peuvent rédiger et publier des
            commentaires sur le Site.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 8 – RESPONSABILITÉ
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Responsabilisé des Utilisateurs
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Tous les Utilisateurs sont entièrement responsables des informations qu’ils
            publient sur le Site ainsi que des Articles qu’ils proposent, vendent ou
            transfèrent à d’autres Utilisateurs. Sans limiter ce qui précède, les
            Utilisateurs reconnaissent et conviennent qu’ils sont entièrement responsables
            de l’ajout des Articles dans le Catalogue, ainsi que de la description, des
            photographies et des vidéos de ceux-ci, de toutes les informations qu’ils
            publient sur le Site, des déclarations faites aux Acheteurs ou à d’autres
            Utilisateurs et de l’exactitude des autres informations fournies (y compris
            les Messages Privés et les commentaires) (ci-après dénommés ensemble le
            Contenu). En outre, les Utilisateurs sont seuls responsables des Transactions
            effectuées avec d’autres Utilisateurs et des litiges qui pourraient en
            résulter, sauf dans les cas où des litiges résulteraient du non-respect par
            SCALE de ses obligations découlant des présentes CGUV.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Engagements des Utilisateurs
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            L’Utilisateur s’engage à se conformer à toutes les lois, règles et
            réglementations qui lui sont applicables lors de l’utilisation du Site. Les
            Utilisateurs doivent notamment s’abstenir (i) de porter atteinte aux droits de
            tiers, (ii) de porter atteinte aux droits de propriété intellectuelle ou
            d’ajouter au Catalogue des Articles contrefaits, (iii) d’ajouter au Catalogue
            des Articles qui enfreignent les lois ou réglementations; (iv) d’inciter à
            commettre des infractions ou des crimes, et d’encourager toute pratique
            discriminatoire, haineuse ou violente fondée sur la race, l’origine ethnique
            ou la nationalité, (v) de communiquer des informations incorrectes ou des
            informations confidentielles, (vi) de faire des commentaires diffamatoires,
            (vii) de commettre
          </span>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            <br />
          </span>
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            des actes susceptibles de mettre des mineurs en danger, (viii) de publier les
            données à caractère personnel d’autres personnes ou de violer les droits
            afférents à la vie privée, (ix) d’usurper l’identité d’autrui, ou (x) de
            téléverser ou de publier par tout autre moyen des Contenus illicites, faux ou
            inexacts. Les Utilisateurs s’engagent à agir et à utiliser le Site de bonne
            foi.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Engagements de SCALE
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE agit en tant que simple intermédiaire entre les Acheteurs et les
            Vendeurs. Si un Utilisateur ou un Visiteur signale à SCALE une faute commise
            par autre Utilisateur ou Visiteur, SCALE pourra apporter son assistance à la
            partie concernée. SCALE coopérera également avec les autorités locales si
            nécessaire. Dans toute la mesure permise par la loi applicable, l’Utilisateur
            reconnaît et convient qu’il est seul responsable de toute perte directe et
            indirecte subie par des tiers ou par SCALE, s’il ne se conforme pas aux
            dispositions de l’article 8 ou enfreint d’une quelconque façon les présentes
            Conditions Générales. Par conséquent, l’Utilisateur reconnaît et convient que,
            dans la mesure permise par la loi applicable et sauf si elle enfreint ces
            Conditions Générales, SCALE ne sera en aucun cas tenue responsable des
            dommages ou pertes subis par les Visiteurs ou les Utilisateurs et/ou par des
            tiers. En particulier, sans que cette liste ne soit exhaustive, SCALE ne
            saurait être tenue responsable : (i) des actions ou omissions des
            Utilisateurs, (ii) de l’adéquation, l’exactitude, l’exhaustivité et/ou la
            licéité des Contenus ; (iii) de la sécurité, la qualité et la quantité des
            Articles que les Utilisateurs vendent ou achètent via le Site ou de la
            conformité d’un Article avec la description qui en est donnée, (iv) de tout
            problème concernant les Transactions, sauf si cela est expressément inclus
            dans la Protection Acheteurs.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Responsabilité de SCALE
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE n’est pas responsable du Contenu généré par les Utilisateurs. Les
            Utilisateurs sont responsables de leur Contenu. L’Utilisateur reconnaît et
            convient qu’en sa qualité d’hébergeur, SCALE n’a aucune obligation
            contractuelle de vérifier le Contenu et les Articles ajoutés au Catalogue et,
            dans la mesure permise par la loi applicable, ne sera en aucun cas tenue
            responsable des dommages ou pertes subis par les Visiteurs ou les Utilisateurs
            et/ou des tiers en ce qui concerne ces Contenus et Articles. Conformément à
            ses obligations légales, SCALE retirera du Site tout Contenu illicite qui lui
            aurait été dûment signalé, de manière suffisamment détaillée et claire. Si
            SCALE détecte des Contenus ou Articles qui enfreignent ou sont susceptibles
            d’enfreindre les lois applicables et/ou les présentes CGUV, SCALE agira à
            l’encontre de l’Utilisateur selon les modalités prévues à l’article 10.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur ou le Visiteur doivent informer SCALE immédiatement, par
                l’un des moyens suivants, s’ils constatent l’existence d’un Contenu
                promouvant des crimes contre l’humanité, incitant à la haine et/ou à la
                violence raciale et/ou à commettre des actes terroristes, ayant un
                caractère pédopornographique ou tout autre contenu illégal, en suivant la
                procédure de signalement décrite ici :
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                en envoyant un e-mail à l’adresse
              </span>
              <span
                style={{
                  color: '#0000ff',
                  backgroundColor: '#ffffff',
                  fontSize: '12pt',
                }}
              >
                &nbsp;
              </span>
              <a href="mailto:contact@scaleconsign.fr">
                <u>
                  <span
                    style={{
                      color: '#0000ff',
                      backgroundColor: '#ffffff',
                      fontSize: '12pt',
                    }}
                  >
                    contact@scaleconsign.fr
                  </span>
                </u>
                <span
                  style={{
                    color: '#0000ff',
                    backgroundColor: '#ffffff',
                    fontSize: '12pt',
                  }}
                >
                  &nbsp;
                </span>
              </a>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>;</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                en envoyant un message auprès du service après-vente de SCALE sur
                Instagram/Discord
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Si un Contenu de ce type est signalé, SCALE se réserve le droit de suspendre
            immédiatement et sans préavis le compte de l’Utilisateur (y compris toute
            Transaction en cours) et de supprimer le Contenu, comme prévu à l’article 10.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Si un Utilisateur estime qu’un Contenu pourrait enfreindre les présentes
                Conditions Générales, ses droits et/ou les droits d’un tiers (par exemple,
                pour des faits de contrefaçon, d’insulte, d’atteinte au droit au respect
                de la vie privée ou de sécurité des produits), cet Utilisateur peut en
                informer SCALE par les moyens suivants :
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                en envoyant un e-mail à l’adresse
              </span>
              <span
                style={{
                  color: '#0000ff',
                  backgroundColor: '#ffffff',
                  fontSize: '12pt',
                }}
              >
                &nbsp;
              </span>
              <a href="mailto:contact@scaleconsign.fr">
                <u>
                  <span
                    style={{
                      color: '#0000ff',
                      backgroundColor: '#ffffff',
                      fontSize: '12pt',
                    }}
                  >
                    contact@scaleconsign.fr
                  </span>
                </u>
                <span
                  style={{
                    color: '#0000ff',
                    backgroundColor: '#ffffff',
                    fontSize: '12pt',
                  }}
                >
                  &nbsp;
                </span>
              </a>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>;</span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>en</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                envoyant
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>un</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                message
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>auprès</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>du</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                service
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                après-vente
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>de</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>SCALE</span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                &nbsp; &nbsp;&nbsp;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                sur Instagram/Discord
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={7}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Si un Article présente un problème de sécurité, il est conseillé aux
                Utilisateurs de cesser de l’utiliser.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={8}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE décline toute responsabilité quant aux obligations fiscales ou
                déclaratives qui pourraient découler des activités des Utilisateurs sur le
                Site. En ce qui concerne les Transactions effectuées sur le Site, les
                Utilisateurs sont seuls responsables des obligations fiscales résultant,
                le cas échéant, à la législation applicable.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={9}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Conformément aux lois et règlements applicables, SCALE s’engage à prendre
                toutes les mesures appropriées pour protéger la sécurité des données
                fournies par les Utilisateurs et pour empêcher nos Services de
                compromettre les données et les logiciels stockés sur les ordinateurs,
                tablettes ou smartphones de l’Utilisateur. Toutefois, même si SCALE a pris
                les mesures appropriées en conformité avec les exigences légales, ses
                systèmes peuvent encore être compromis par des événements imprévisibles
                tels que des cyberattaques ou des failles de sécurité concernant la
                transmission de données ou affectant le volume et le débit des
                transmissions de données. Dans ces conditions, nous suggérons vivement aux
                Utilisateurs de prendre toutes les mesures appropriées pour protéger leurs
                propres données et/ou logiciels, notamment contre toute contamination par
                des virus circulant sur Internet.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <strong>
            <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
              ARTICLE 9 – OBLIGATIONS DE L’UTILISATEUR
            </span>
          </strong>
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lors de la fourniture des Services, SCALE s’efforce de faire en sorte que
                les Utilisateurs puissent utiliser le Site en toute sécurité. Néanmoins,
                le maintien de la sûreté et de la sécurité de cet environnement dépend
                fortement des Utilisateurs et de leur comportement. Pour cette raison,
                lors de l’utilisation du Site, les Utilisateurs s’engagent à suivre les
                règles stipulées dans les présentes Conditions Générales, y compris, mais
                sans s’y limiter, les règles décrites dans le présent article 8 et les
                articles 10.2 et 10.3 ci-dessous.
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lorsqu’il utilise le Site, l’Utilisateur s’engage à respecter les règles
                suivantes, et notamment :
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                lors de l’inscription sur le Site, à fournir des informations exactes le
                concernant (y compris, sans s’y limiter, son nom et son adresse
                électronique) ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à ne s’inscrire sur le Site qu’une seule fois et à ne pas créer plus d’un
                Compte ; sauf si un tiers parvient à accéder au Compte d’un Utilisateur,
                celui-ci pourra créer un autre Compte, mais seulement après que
                l’Utilisateur en aura informé SCALE et que SCALE aura bloqué le premier
                Compte ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à ne pas utiliser le Site et/ou les Services avec l’intention de commettre
                des actes ou d’effectuer des transactions illicites ou frauduleuses;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                lors de l’utilisation du Site, à fournir des informations objectives,
                correctes, exhaustives et détaillées sur les Articles vendus ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à s’assurer que le prix proposé pour la vente des Articles, ainsi que les
                autres informations les concernant, sont corrects ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à ne pas copier des contenus ou informations figurant sur le Site et
                téléversés par SCALE ou par un autre Utilisateur du Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à ne pas utiliser les contenus ou informations figurant sur le Site à des
                fins illicites,
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                à ne pas dépasser le seuil de 5.000 € pour la totalité des Transactions.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lors de l’utilisation du Site, les Utilisateurs s’engagent à ne pas, et
                les Visiteurs s’interdisent de :
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                partager, publier ou utiliser de quelque façon que ce soit des
                informations et/ou des données trompeuses ou incorrectes;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                encourager l’acquisition de tout produit dont la vente ou la détention
                seraient interdites ou restreintes;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                utiliser (en particulier partager et/ou publier) des photographies (i) sur
                lesquelles l’Utilisateur concerné ne détiendrait pas les droits de
                propriété intellectuelle (en général, cela concerne des photos trouvées
                sur Internet), ou (ii) qui contiendraient des liens vers d’autres sites
                Internet;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                utiliser (en particulier partager et/ou publier) des photographies (i) sur
                lesquelles des personnes autres que l’Utilisateur concerné seraient
                visibles, à moins que ces autres personnes n’aient consenti à la
                publication de ces photographies ou (ii) qui seraient ou pourraient être
                considérées comme contraires aux bonnes mœurs et/ou à la sécurité publique
                (par exemple, des photographies érotiques, pornographiques ou violentes) ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                publier des Contenus louant, promouvant ou encourageant le terrorisme, le
                racisme, le révisionnisme, la xénophobie, l’homophobie, le sexisme,
                l’incitation à la haine, la discrimination, la traite des êtres humains,
                le crime organisé, les organisations illégales, l’automutilation, le
                suicide, la torture, la cruauté envers les animaux, l’apologie de crimes
                de guerre, l’exploitation sexuelle des enfants et/ou des adultes, les
                sectes ;
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                proposer, vendre, acheter et/ou transférer des Articles en violation des
                droits de propriété intellectuelle de tiers, tels que des marques déposées
                et/ou d’autres signes protégés par la loi applicable (comme par exemple
                les logos de société dans certaines juridictions), les dessins et modèles
                et/ou les droits d’auteur ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                violer les droits de propriété et/ou les droits personnels de tiers (y
                compris les droits de propriété intellectuelle) ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                enfreindre les lois et/ou réglementations applicables ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                agir de façon contraire à la sécurité publique et/ou aux bonnes mœurs ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                utiliser (en particulier partager et/ou publier) des programmes et/ou des
                fichiers informatiques contenant des virus ou qui pourraient (i) perturber
                le fonctionnement normal du Site et/ou des Services, (ii) infecter les
                ordinateurs des Utilisateurs et, par conséquent, entraîner des pertes pour
                l’Utilisateur ou affecter ses actifs ou (iii) empêcher l’Utilisateur
                d’utiliser le Site, les Services ou l’ordinateur de l’Utilisateur;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                proposer, partager et/ou de quelque autre façon promouvoir des liens vers
                d’autres sites internet et/ou sociétés qui fourniraient des services
                similaires à ceux proposés par le Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                proposer, partager ou de quelque autre façon promouvoir des liens vers
                d’autres sites internet et/ou sociétés qui fourniraient des services
                similaires à ceux proposés par le Site ou vers des sites Web ou des
                sociétés dont les contenus ou l’activité seraient illicites ou contraires
                à nos politiques de contenu;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                explorer des données (
              </span>
              <em>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  data mining
                </span>
              </em>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ), gratter (
              </span>
              <em>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  screen scraping
                </span>
              </em>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ) ou utiliser des robots d’indexation (
              </span>
              <em>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  crawling
                </span>
              </em>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ) sur une quelconque partie du Site ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                désassembler, décompiler ou rétro-concevoir une quelconque partie du Site
                ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                adapter, copier, modifier, corriger, distribuer ou commercialiser tout
                contenu du Site sans le consentement écrit préalable de SCALE.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Les Utilisateurs s’engagent à ne pas, et les Visiteurs s’interdisent, de :
                collecter, détenir, transmettre à des tiers, rendre publiques, publier ou
                divulguer des données relatives à des Utilisateurs du Site ou à des
                activités des Utilisateurs du Site, y compris des Transactions, leur
                nombre, leur type, leur prix, etc., si ces données sont devenues
                disponibles de manière illicite ou à la suite d’une action illicite ou
                d’une omission.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur s’engage à préserver la confidentialité des identifiants de
                connexion et du mot de passe de son Compte et à ne pas les divulguer à des
                tiers (à l’exception des personnes que l’Utilisateur autoriserait à
                utiliser ses identifiants et son mot de passe). L’Utilisateur est seul
                responsable (vis-à-vis de SCALE et d’autrui) de toutes les activités ayant
                lieu sur son Compte. Si un Utilisateur apprend ou soupçonne qu’un tiers
                non autorisé connaît son mot de passe ou a accédé à son Compte, il doit en
                informer SCALE dans les plus brefs délais via le formulaire de contact.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur s’engage à modifier immédiatement toute information figurant
                sur le Site qui ne serait plus à jour ou exacte, y compris les
                informations fournies par l’Utilisateur lors de son inscription sur le
                Site et les informations sur le Compte de l’Utilisateur, ainsi que les
                informations sur les Articles référencés dans le Catalogue.
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 10 – DROIT DE SCALE DE RESTREINDRE ET BLOQUER LES
              </span>
            </u>
          </strong>
          <strong>
            <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>&nbsp;</span>
          </strong>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                COMPTES
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Restriction et blocage des comptes Utilisateur
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Afin d’assurer un environnement sûr et sécurisé aux Utilisateurs du Site,
            SCALE peut, y compris par l’utilisation de logiciels automatisés et/ou
            d’algorithmes :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Restreindre le Compte d’un Utilisateur. Les Utilisateurs dont les Comptes
                font l’objet d’une restriction i) ne peuvent pas utiliser ou accéder à
                certaines des fonctionnalités du Site, telles que, mais sans s’y limiter,
                la possibilité de référencer des Articles, d’effectuer des Transactions,
                de transférer des fonds détenus sur leur porte-monnaie électronique SCALE,
                d’envoyer des Messages Privés à tous les autres Utilisateurs, de voir les
                Articles référencés par tous les autres Utilisateurs ou
              </span>
            </p>
          </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            ii) subiront une visibilité réduite de leurs Articles sur les pages de
            résultats de recherche ; et/ou
          </span>
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Bloquer temporairement ou définitivement le Compte d’un Utilisateur. Les
                Utilisateurs dont les Comptes sont bloqués ne sont plus autorisés à
                accéder au Site. Le cas échéant, ces Utilisateurs seront en mesure de
                finaliser toute Transaction en cours et de transférer les fonds détenus
                dans leur porte-monnaie électronique SCALE sur leur compte bancaire, à
                condition qu’ils aient accompli les vérifications KYC applicables (le cas
                échéant). Toutefois, si un Compte a été bloqué parce que sa sécurité avait
                été compromise ou que le prestataire de services de paiement a détecté un
                comportement frauduleux de la part de l’Utilisateur, ou afin d’assurer la
                protection des intérêts et des droits des autres Utilisateurs de la
                plateforme, ces Utilisateurs pourraient ne plus être en mesure,
                conformément à la loi applicable, de transférer les fonds détenus sur leur
                porte-monnaie électronique SCALE (Balance SCALE) vers leur compte
                bancaire. Si SCALE bloque le Compte d’un Utilisateur, cela pourra empêcher
                cet Utilisateur de s’inscrire à nouveau sur le Site.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE s’engage à prendre des mesures de restriction ou de blocage qui (i)
            tiennent compte des intérêts raisonnables des Utilisateurs lors de la prise de
            ces mesures, (ii) sont proportionnées aux circonstances et, en particulier,
            aux violations commises par l’Utilisateur. Les manquements commis par les
            Utilisateurs entraîneront un blocage et/ou une restriction temporaire(s) de
            son Compte, sauf si (i) l’Utilisateur dont le Compte a déjà été temporairement
            bloqué et/ou restreint ne s’abstient pas de commettre d’autres infractions, ou
            (ii) ces manquements sont graves ou répétés, auquel cas le Compte de
            l’Utilisateur sera définitivement bloqué.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Blocage avec préavis
                </span>
              </u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            SCALE pourra restreindre et/ou bloquer le Compte d’un Utilisateur, après en
            avoir informé cet Utilisateur par écrit et respecté un délai de préavis
            raisonnable, dans le cas où cet Utilisateur :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Enfreindrait les présentes CGUV ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Fournirait ou publierait, lors de son inscription sur le Site ou de
                l’utilisation du Site, des informations incorrectes, trompeuses, obsolètes
                et/ou incomplètes au sens des présentes CGUV ou ne tiendrait pas ces
                informations à jour ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Insulterait délibérément et volontairement d’autres personnes ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Ferait un usage abusif des fonctionnalités fournies par le Site ou les
                utiliserait de mauvaise foi, par exemple en abusant de la possibilité de
                signaler des Contenus ou de faire appel des décisions de SCALE de
                restreindre l’utilisation du Site, de bloquer des comptes ou de supprimer
                des contenus ;
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Blocage immédiat
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Nonobstant ce qui précède, SCALE pourra restreindre et/ou bloquer le compte de
            l’Utilisateur avec effet immédiat et sans préavis ou mise en demeure préalable
            dans les cas suivants :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Compte d’un Utilisateur signalé par le prestataire de services de
                paiement. Le prestataire de services de paiement peut signaler à SCALE
                tout Compte d’Utilisateur si le prestataire de services de paiement a des
                raisons sérieuses de soupçonner que, en utilisant des services de paiement
                fournis par le prestataire de services de paiement, cet Utilisateur a
                enfreint les lois applicables et/ou les conditions générales du
                prestataire de services de paiement et/ou utilisé le Site de manière
                abusive (notamment en cas de ventes illégales sur le Site, de blanchiment
                d’argent, de fraude, d’usurpation d’identité ou d’utilisation de faux
                documents);
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Litige initié par l’Utilisateur auprès d’un prestataire de services de
                paiement tiers concernant un paiement. Si un Utilisateur initie un litige
                concernant une Transaction auprès d’un prestataire de services de paiement
                tiers et qu’il existe des raisons objectives et légitimes de croire que,
                dans ce contexte, l’Utilisateur a violé les lois applicables, enfreint les
                présentes Conditions Générales et/ou fait un usage abusif du Site, SCALE
                peut immédiatement restreindre et/ou bloquer le Compte de l’Utilisateur (y
                compris suspendre et/ou annuler les Transactions en cours).
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Violation des Lois Applicables, de la Sécurité Publique ou de la Sécurité
                des Utilisateurs. L’usage par l’Utilisateur des Services ou les Contenus
                publiés par l’Utilisateur enfreignent manifestement une loi,
                réglementation ou règle relative à la sécurité publique ou sont
                susceptibles d’avoir des conséquences graves sur la santé, la sécurité ou
                les intérêts économiques légitimes de SCALE, d’autres Utilisateurs,
                d’Utilisateurs Professionnels ou de tiers. Par exemple :
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur a téléchargé sur le Contenu du Site des contenus faisant
                l’apologie de crimes contre l’humanité,
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            incitant à la haine raciale et/ou la violence ou de nature pédopornographique
            ;
          </span>
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur a usurpé l’identité d’un autre Utilisateur ou d’une autre
                personne ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Il existe des indices objectifs et concordants selon lesquels une fraude
                de quelque nature que ce soit a été commise ou est commise par un
                Utilisateur ou selon lesquels un Utilisateur détourne la plateforme d’une
                manière qui met en danger la sécurité d’autres Utilisateurs ou de la
                plateforme, y compris, sans s’y limiter, les connexions à partir
                d’adresses IP suspectes, l’escroquerie d’autres Utilisateurs ou l’envoi
                d’e-mails indésirables (
              </span>
              <em>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  spamming
                </span>
              </em>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ) à d’autres Utilisateurs. SCALE s’engage à instruire de façon diligente
                toute pratique frauduleuse et à examiner rapidement et sérieusement toute
                demande de l’Utilisateur suspecté.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Obligation réglementaire ou motif légal impérieux, lorsque l’observation
                d’un délai de préavis violerait une quelconque loi, réglementation ou
                instruction d’une autorité d’exécution judiciaire, ou risquerait de
                quelque autre façon d’engager la responsabilité de SCALE ou de ses
                sociétés affiliées.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Risque de Sécurité Informatique, lorsqu’un risque menace la sécurité et le
                fonctionnement technique des systèmes informatiques de SCALE.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Violations répétées ou graves, lorsque l’Utilisateur a commis des
                violations répétées des présentes CGUV.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Notification
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Dans les cas visés aux articles 10.1 à 10.3, SCALE adressera à l’Utilisateur
            une notification écrite indiquant les raisons de la restriction ou du blocage
            de son Compte, à moins que cela :
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                N’enfreigne une loi, réglementation ou instruction d’une autorité ou
                risque, de quelque autre façon, d’engager la responsabilité de SCALE ou de
                ses sociétés affiliées ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Ne perturbe une investigation sur une pratique frauduleuse ;
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'disc', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Ne facilite la poursuite ou la répétition d’une pratique frauduleuse,
                d’une violation du droit ou d’une violation grave des présentes CGUV et/ou
                des conditions générales du prestataire de services de paiement.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE pourra enquêter sur toute violation des présentes Conditions
                Générales et, sous réserve des lois applicables telles que les lois sur la
                protection des données à caractère personnel, informer les institutions et
                autorités compétentes.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Appel
                </span>
              </u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Dans le cas où SCALE ferait valoir ses droits en vertu du présent article 9,
            l’Utilisateur concerné pourra contester la décision de SCALE via le formulaire
            de contact.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 11 – DROITS ET OBLIGATIONS DE SCALE
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE est rémunéré par un pourcentage de 4 % HT sur chaque transaction à
                la charge du Vendeur.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE peut, à tout moment, aux fins d’améliorer la convivialité du Site,
                réorganiser le Catalogue, les espaces publicitaires ou d’autres éléments
                d’information concernant les Articles, sous réserve que ces modifications
                ne modifient pas le Contenu fourni par l’Utilisateur. Ces modifications
                n’entraîneront pas de préjudices pour les Vendeurs et ne restreindront pas
                les obligations de SCALE en vertu des présentes Conditions Générales.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE peut à tout moment cesser ou suspendre l’exploitation du Site, ou la
                céder à des tiers, sous réserve d’en informer les Utilisateurs et de
                respecter un délai de préavis de trente
              </span>
            </p>
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            (30) jours.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p>
              <u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  Garantie de Conformité
                </span>
              </u>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            Les Utilisateurs basés dans l’Union européenne bénéficient d’une garantie
            légale de conformité couvrant les services numériques fournis par SCALE. Au
            titre de cette garantie, SCALE est responsable de tout défaut de conformité
            existant au moment de la fourniture s’il est découvert par l’Utilisateur dans
            un délai de deux ans à compter de la fourniture ponctuelle de services
            numériques ou de tout défaut de conformité qui survient ou devient apparent au
            cours de la période pendant laquelle le service numérique doit être fourni de
            manière continue par SCALE. Les lois nationales de l’Utilisateur peuvent
            prévoir des garanties plus longues. Afin de soumettre une demande, les
            Utilisateurs peuvent contacter SCALE comme décrit à la Section 15 ci-après.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1 style={{ textAlign: 'justify' }}>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 12 – PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                La Politique de confidentialité, disponible sous le lien
              </span>
              <span
                style={{
                  color: '#0000ff',
                  backgroundColor: '#ffffff',
                  fontSize: '12pt',
                }}
              >
                &nbsp;
              </span>
              <a href="https://scaleconsign.com/CGV%26CGU">
                <u>
                  <span
                    style={{
                      color: '#0000ff',
                      backgroundColor: '#ffffff',
                      fontSize: '12pt',
                    }}
                  >
                    https://scaleconsign.com/CGV&amp;CGU
                  </span>
                </u>
                <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                  ,&nbsp;
                </span>
              </a>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                décrit les données à caractère personnel des Utilisateurs collectées par
                SCALE et les finalités pour lesquelles SCALE traite ces données. La
                Politique de confidentialité et toute partie de celle-ci ne sont pas
                considérées comme des clauses contractuelles et ne font pas partie des
                présentes CGUV.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Il vous appartient de fournir à SCALE des données exactes. Lorsque les
                données vous concernant changent (par exemple, votre adresse ou votre nom
                de famille), vous devez notifier ces changements dans les meilleurs délais
                en modifiant les informations de votre compte.
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Lorsque vous effectuez un traitement de données à caractère personnel
                indépendamment et séparément de SCALE, vous êtes responsable du respect
                des lois applicables en matière de protection des données (notamment le
                Règlement général sur la protection des données).
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1>
          <strong>
            <u>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                ARTICLE 13 – GOUVERNANCE, RÈGLEMENT DES LITIGES ET RÉSILIATION
              </span>
            </u>
          </strong>
        </h1>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Les présentes CGUV sont régies par le droit français. Vous pouvez
                introduire une demande devant les tribunaux de votre résidence ou
                domicile.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                En cas de désaccord entre SCALE et l’Utilisateur, SCALE encourage les
                Utilisateurs à s’adresser en premier lieu à SCALE pour trouver une
                solution amiable. L’Utilisateur peut :
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                tout d’abord adresser &nbsp; une réclamation &nbsp; écrite &nbsp; au
                Service Client &nbsp; à &nbsp; l’aide du formulaire de contact.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur et SCALE s’efforceront de résoudre les réclamations de
                l’Utilisateur à l’amiable.
              </span>
            </p>
          </li>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Si le différend n’est pas résolu ou si l’Utilisateur n’est pas satisfait
                du résultat, il peut utiliser des modes alternatifs de résolution des
                différends tels que la médiation de la consommation. SCALE ne s’engage pas
                à utiliser de telles méthodes pour résoudre les litiges avec les
                Utilisateurs et n’est pas tenue de le faire. Les litiges entre les
                Vendeurs et les Acheteurs ne sont pas éligibles à la médiation de la
                consommation et ne sont pas de la responsabilité de SCALE.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur peut mettre fin à sa relation avec SCALE à tout moment avec
                effet immédiat mais devra remplir toutes ses obligations résultant des
                Transactions que l’Utilisateur a conclues avant la fin de sa relation avec
                SCALE. L’Utilisateur peut mettre fin à sa relation avec SCALE en mettant
                fin à son abonnement de la plateforme One CookLab ce qui mettre fin à son
                accès au Site. L’Utilisateur souhaitant supprimer son compte doit
                contacter préalablement un e-mail à l’adresse
              </span>
              <span
                style={{
                  color: '#0000ff',
                  backgroundColor: '#ffffff',
                  fontSize: '12pt',
                }}
              >
                &nbsp;
              </span>
              <a href="mailto:contact@scaleconsign.fr">
                <u>
                  <span
                    style={{
                      color: '#0000ff',
                      backgroundColor: '#ffffff',
                      fontSize: '12pt',
                    }}
                  >
                    contact@scaleconsign.fr
                  </span>
                </u>
                <span
                  style={{
                    color: '#0000ff',
                    backgroundColor: '#ffffff',
                    fontSize: '12pt',
                  }}
                >
                  &nbsp;
                </span>
              </a>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                en précisant sa demande.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE et les sociétés de son groupe ainsi que ses et leurs concédants de
                licence détiennent tous les droits, notamment les droits de propriété
                intellectuelle sur le Site, notamment les droits afférents à son système
                (les algorithmes permettant au Catalogue de fonctionner, sa transmission,
                etc.), à la mise en page et à la conception du Site, aux logiciels
                utilisés par et pour le Site, aux marques et noms de domaine utilisés par
                et pour le Site.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                Les CGUV n’ont pas pour effet de créer entre SCALE et les Utilisateurs un
                partenariat (quelle que soit sa forme juridique), des relations relevant
                du droit du travail, des relations entre un agent commercial et son
                client, ni des relations de franchise.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={6}>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                SCALE peut, sous réserve des lois applicables en matière de protection des
                données à caractère personnel, transférer et/ou céder tous les droits et
                obligations découlant des présentes CGUV à un tiers, notamment en cas de
                transfert d’un secteur d’activité, de fusion par création
              </span>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                <br />
              </span>
            </p>
          </li>
        </ol>
        <p style={{ textAlign: 'justify' }}>
          <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
            d’une nouvelle société, de fusion par absorption, de scission ou de tout
            changement de contrôle affectant SCALE, à condition que cela ne réduise pas la
            protection des droits du consommateur. Ce transfert libérera SCALE de toutes
            les obligations futures liées à l’exploitation du Site, notamment envers ses
            Utilisateurs et Visiteurs. Dans le cas où SCALE transférait et/ou céderait à
            un tiers les droits et obligations découlant des présentes CGUV, SCALE devrait
            informer les Utilisateurs de ce transfert et/ou de cette cession 15 jours à
            l’avance et l’Utilisateur a le droit de mettre fin immédiatement à sa relation
            et de fermer son compte auprès de SCALE. Les Utilisateurs ne peuvent
            transférer ou céder tout ou partie de leurs droits ou obligations découlant
            des présentes Conditions Générales.
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol>
          <li style={{ listStyleType: 'decimal', fontSize: '12pt' }}>
            <p style={{ textAlign: 'justify' }}>
              <span style={{ backgroundColor: '#ffffff', fontSize: '12pt' }}>
                L’Utilisateur peut, à tout moment, exprimer des objections concernant
                l’accès, l’utilisation ou l’exploitation du Site ou des Services en
                envoyant une notification à cet effet. Les notifications doivent être
                adressées à SCALE en utilisant le formulaire figurant sur le Site ou par
                voie postale à SCALE sis 10 rue Béranger – 77300 FONTAINBLEAU - FRANCE.
              </span>
            </p>
          </li>
        </ol>
      </>
    </>
  );
}
