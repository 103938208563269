import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TRegisterFormStep4, ZRegisterFormStep4Schema } from '@/types/Partner/onboarding';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRegisterPartnerStep4 } from '@/api/services/app';
import { useEffect } from 'react';

type PartnerSignFormProps = React.HTMLAttributes<HTMLDivElement>;

export function RegisterFormStep4({ className, ...props }: PartnerSignFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = sessionStorage.getItem('registerPartnerStep4');
    if (data) {
      try {
        const dataParse = JSON.parse(data) as TRegisterFormStep4;
        form.setValue('holderName', dataParse.holderName);
        form.setValue('iban', dataParse.iban);
        form.setValue('bic', dataParse.bic);
        form.setValue('proofOfBankAccount', dataParse.proofOfBankAccount);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  const form = useForm<TRegisterFormStep4>({
    resolver: zodResolver(ZRegisterFormStep4Schema),
    defaultValues: {
      holderName: '',
      iban: '',
      bic: '',
      proofOfBankAccount: '',
    },
  });
  const { setValue } = form;

  const onChangeFile = (file: File, type: keyof TRegisterFormStep4) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  const onSubmit = (values: TRegisterFormStep4) => {
    values.bic = values.bic.toUpperCase().replace(/\s/g, '');
    sessionStorage.setItem('registerPartnerStep4', JSON.stringify(values));
    dispatch(setRegisterPartnerStep4(values));
    navigate(`/partner/register/${token}/step5`);
  };

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="holderName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('holder_name')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="iban"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('iban')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="bic"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('bic')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="proofOfBankAccount"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('proof_of_bank_account')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'proofOfBankAccount');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit">{t('next')}</Button>
            <Button
              variant="outline"
              onClick={() => navigate(`/partner/register/${token}/step3`)}
            >
              {t('previous')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
