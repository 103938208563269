import { z } from 'zod';
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types/results';

export enum SNEAKERS_SIZE_TYPE_STOCKX {
  'infant' = 'infant',
  'child' = 'child',
  'women' = 'women',
  'men' = 'men',
  'unisex' = 'unisex',
}

export const ZStatisticsSchema = z.object({
  expectedProfitInventory: z.number(),
  stockItemCount: z.number(),
  monthlyAuthenticatedSalesTotal: z.number(),
  yearlyAuthenticatedSalesTotal: z.number(),
});

export const ZItemSchema = z.object({
  objectId: z.string().optional(),
  id: z.number().optional(),
  size: z.coerce.number().min(1, {
    message: 'required_field',
  }),
  price: z.coerce.number().min(1, {
    message: 'required_field',
  }),
});

export const ZInventorySchema = z.object({
  imageUrl: z.string(),
  id: z.number(),
  name: z.string(),
  sku: z.string(),
  size: z.string(),
  slug: z.string(),
  gender: z.nativeEnum(SNEAKERS_SIZE_TYPE_STOCKX),
  product_category: z.string(),
  productCategory: z.string(),
  sizeId: z.coerce.number().min(1, {
    message: 'required_field',
  }),
  listPrice: z.coerce.number().min(1, {
    message: 'required_field',
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const ZAddItemFormSchema = z.object({
  products: z.array(ZItemSchema).nonempty({ message: 'required' }),
});

export const ZEditItemFormSchema = ZItemSchema;

export type TAddItemForm = z.infer<typeof ZAddItemFormSchema>;
export type TEditItemForm = z.infer<typeof ZEditItemFormSchema>;
export type TItem = z.infer<typeof ZItemSchema>;
export type TInventory = z.infer<typeof ZInventorySchema>;
export type TInventoryStatistics = z.infer<typeof ZStatisticsSchema>;

export type Size = {
  id: number;
  label: string;
};

export type HitType = AlgoliaHit<{
  name: string;
  image: string;
  brand: string;
  ticker_symbol: string;
  thumbnail_url: string;
  categories: string[];
  gender: SNEAKERS_SIZE_TYPE_STOCKX;
  product_category: string;
  _highlightResult: {
    searchable_traits: {
      [key: string]: {
        value: string;
      };
    };
  };
}>;
