import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';
import { NavItem } from '@/types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

interface DashboardNavProps {
  items: NavItem[];
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export function MenuItemsSidebar({ items, open, setOpen }: DashboardNavProps) {
  const { t } = useTranslation();
  const location = useLocation();

  if (!items?.length) {
    return null;
  }

  const closeSidebar = () => {
    if (setOpen) {
      setOpen(!open);
    }
  };

  return (
    <>
      {items.map((item, index) => {
        const Icon = Icons[item.icon || 'arrowRight'];
        return (
          item.href && (
            <Link key={index} to={item.disabled ? '/' : item.href} onClick={closeSidebar}>
              <span
                className={cn(
                  'flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:bg-muted',
                  location.pathname === item.href
                    ? 'bg-indigo-50 text-indigo-700'
                    : 'transparent',
                  item.disabled && 'cursor-not-allowed opacity-80',
                )}
              >
                {item.icon ? <Icon className="h-5 w-5" /> : ''}
                <span>{t(item.title)}</span>
              </span>
            </Link>
          )
        );
      })}
    </>
  );
}
