import {
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { useToast } from '@/components/ui/use-toast';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';

export default function DropdownItems() {
  const { t } = useTranslation();
  const { data: me } = useGetMeSellerQuery();
  const navigate = useNavigate();
  const { toast } = useToast();

  const kycStatus = me?.kycStatus;
  const isPending = kycStatus === ONBOARDING_STATUS_SELLER.PENDING;
  const isNotStarted = kycStatus === ONBOARDING_STATUS_SELLER.NOT_STARTED;
  const isRejected = kycStatus === ONBOARDING_STATUS_SELLER.REJECTED;

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenType');
    toast({
      variant: 'success',
      title: t('logout_successfully'),
    });
    navigate('/');
  };

  return (
    <>
      {me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED ? (
        <>
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <Link to={'/personal-information'} className="cursor-pointer">
                {t('personal_information')}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to={'/bank-information'} className="cursor-pointer">
                {t('payment_methods')}
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to={'/address'} className="cursor-pointer">
                {t('address')}
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </>
      ) : (
        <>
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <Link to={'/onboarding'} className="cursor-pointer">
                {isNotStarted
                  ? t('banner.onboarding_not_started_button')
                  : isPending
                    ? t('banner.onboarding_pending_button')
                    : isRejected
                      ? t('banner.onboarding_not_started_button')
                      : ''}
              </Link>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </>
      )}

      <DropdownMenuSeparator />
      <DropdownMenuItem asChild>
        <Link
          to={''}
          onClick={() => logout()}
          className="cursor-pointer focus:bg-red-100 focus:text-red-900"
        >
          {t('logout')}
        </Link>
      </DropdownMenuItem>
    </>
  );
}
