import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import {
  HitType,
  Size,
  TAddItemForm,
  TItem,
  ZAddItemFormSchema,
} from '@/types/Seller/inventory';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGetReferencesSellerQuery } from '@/api/services/Seller/onboarding';
import { Loader2, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { usePostRequestPartnerMutation } from '@/api/services/Partner/request';

interface AddDemandFormProps extends React.HTMLAttributes<HTMLDivElement> {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setItemSelected: React.Dispatch<React.SetStateAction<HitType | undefined>>;
  itemSelected: HitType;
}

export function AddRequestForm({
  itemSelected,
  setOpenDialog,
  setItemSelected,
  className,
  ...props
}: AddDemandFormProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [sizes, setSizes] = useState<Size[]>([]);
  const [updateTotalPrice, setUpdateTotalPrice] = useState<boolean>(false);
  const { data: references } = useGetReferencesSellerQuery();
  const [postItem, { isLoading: isLoadingPostItem }] = usePostRequestPartnerMutation();

  useEffect(() => {
    if (references && itemSelected) {
      const { product_category, gender } = itemSelected;

      if (product_category === 'sneakers') {
        setSizes(references.sizes.sneakers[gender] || []);
      } else {
        const defaultSizes = references.sizes[product_category].default;
        setSizes(defaultSizes);
      }
    }
  }, [itemSelected, references]);

  const form = useForm<TAddItemForm>({
    resolver: zodResolver(ZAddItemFormSchema),
    defaultValues: {
      products: [
        {
          objectId: '',
          size: 0,
          price: 0,
        },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    name: 'products',
    control: form.control,
  });

  const { getValues, setValue } = form;

  const removeItem = (index: number) => {
    const products = getValues('products');
    products.splice(index, 1);
    setValue('products', products);
  };

  const onSubmit = async (values: TAddItemForm) => {
    const savePromises: Promise<[]>[] = [];
    values.products.forEach(product => {
      const payload: TItem = {
        objectId: itemSelected.objectID,
        size: product.size,
        price: Number(product.price),
      };
      savePromises.push(postItem(payload).unwrap());
    });
    await Promise.all(savePromises)
      .then(() => {
        setOpenDialog(false);
        setItemSelected(undefined);
        toast({
          variant: 'success',
          title: t('article_added_successfully'),
        });
      })
      .catch(err => {
        console.error(err);
        setItemSelected(undefined);
        setOpenDialog(false);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <div className={cn('grid gap-6 mt-5', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ScrollArea className={fields.length > 5 ? 'h-80' : ''}>
            <div className="grid grid-cols-1 gap-1">
              {fields.map((_, index) => {
                return (
                  <div key={index}>
                    <div className="grid grid-cols-2 gap-2 m-1">
                      <div>
                        <FormField
                          control={form.control}
                          key={index + 1}
                          name={`products.${index}.size`}
                          render={({ field }) => (
                            <FormItem>
                              <div className="grid gap-1">
                                <FormLabel>{t('size')}</FormLabel>
                                <FormControl>
                                  <Select
                                    onValueChange={field.onChange}
                                    value={`${field.value}`}
                                  >
                                    <SelectTrigger className="w-full">
                                      <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {sizes.map(size => (
                                        <SelectItem key={size.id} value={`${size.id}`}>
                                          {size.label}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </FormControl>
                                <FormMessage />
                              </div>
                            </FormItem>
                          )}
                        />
                      </div>
                      <div>
                        <FormField
                          control={form.control}
                          name={`products.${index}.price`}
                          render={({ field }) => (
                            <div className="flex flex-row">
                              <div>
                                <FormItem>
                                  <div className="grid gap-1">
                                    <FormLabel>{t('price')}</FormLabel>
                                    <FormControl>
                                      <Input
                                        type="number"
                                        {...field}
                                        onClick={() =>
                                          setUpdateTotalPrice(!updateTotalPrice)
                                        }
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </div>
                                </FormItem>
                              </div>
                              <div>
                                <a
                                  onClick={() => removeItem(index)}
                                  className={index === 0 ? 'invisible' : 'cursor-pointer'}
                                >
                                  <Trash2 size={15} className="ml-2 mt-7" />
                                </a>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollArea>
          <div className="text-center cursor-pointer text-sm text-indigo-700 mt-4">
            <a
              onClick={() => {
                append({
                  objectId: '',
                  price: 0,
                  size: 0,
                });
              }}
            >
              {t('add_size')}
            </a>
          </div>
          {isLoadingPostItem ? (
            <Button disabled variant="primary" className="w-full mt-2">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('add_item')}
            </Button>
          ) : (
            <>
              <Button type="submit" variant="primary" className="w-full mt-2">
                {t('add_item')}
              </Button>
            </>
          )}
        </form>
      </Form>
    </div>
  );
}
