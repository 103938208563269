import { MenuItemsSidebar } from '@/components/layouts/menu-items-sidebar';
import { NavItem } from '@/types';
import { Link } from 'react-router-dom';
import { Icons } from '../../icons';
import BankSoldPartner from '@/components/Partner/layout/bank-sold-partner';
import { DropdownNavbarPartner } from '@/components/Partner/layout/dropdown-navbar-partner';

export default function SidebarDesktopPartner({
  navItems,
  title,
}: {
  navItems: NavItem[];
  title: string;
}) {
  return (
    <div className="hidden border-r bg-muted/40 lg:block">
      <div className="fixed flex h-full flex-col w-[250px] max-h-screen gap-10">
        <nav className="grid items-start px-2 text-sm font-medium lg:px-2 gap-2">
          <Link
            to="/partner/dashboard"
            className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary mt-3 mb-3"
          >
            <Icons.logo style={{ width: '130px' }} />
          </Link>
          {title && (
            <h2 className="mb-4 mt-4 px-4 text-xl font-semibold tracking-tight">
              {title}
            </h2>
          )}
          <MenuItemsSidebar items={navItems} />
        </nav>
        <nav className="grid items-start mt-auto text-sm font-medium">
          <BankSoldPartner />
          <div className="border-t">
            <DropdownNavbarPartner />
          </div>
        </nav>
      </div>
    </div>
  );
}
