import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '@/components/icons';
import { useEffect } from 'react';
import { ResetPassword } from '@/components/Partner/reset-password-form';
import { usePostMagicLinkMutation } from '@/api/services/Partner/login';
import { useToast } from '@/components/ui/use-toast';

export default function ResetPasswordPartner() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [postMagicLink] = usePostMagicLinkMutation();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const user = queryParams.get('user');
    const expires = queryParams.get('expires');
    const hash = queryParams.get('hash');
    if (user && expires && hash) {
      postMagicLink(location.search)
        .unwrap()
        .then(res => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('tokenType', 'partner');
          toast({
            variant: 'success',
            title: t('login_successfully'),
          });
          navigate('/partner/dashboard');
        })
        .catch(err => {
          console.error(err);
          toast({
            variant: 'destructive',
            title: t('an_error_occurred'),
          });
          navigate('/partner/login');
        });
    }
  }, []);

  return (
    <>
      <div className="w-full grid lg:grid-cols-2 h-dvh">
        <div className="flex items-center justify-center py-12">
          <div className="absolute top-8 left-auto">
            <Icons.logo style={{ width: '140px' }} />
          </div>
          <div className="mx-auto grid lg:w-[400px] w-[350px] pt-12 gap-6">
            {!location.pathname.includes('pending') && (
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">{t('reset_password')}</h1>
              </div>
            )}
            <p className="text-muted-foreground text-center">
              {t('reset_password_description')}
            </p>
            <ResetPassword />
          </div>
        </div>
        <div
          className={`hidden lg:block bg-muted align-middle bg-cover bg-[url('/backgroundPartnerLogin.png')]`}
        ></div>
      </div>
    </>
  );
}
