import * as React from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TRegisterFormStep3, ZRegisterFormStep3Schema } from '@/types/Partner/onboarding';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGetReferencesPartnerQuery } from '@/api/services/Partner/onboarding';
import { useDispatch } from 'react-redux';
import { setRegisterPartnerStep3 } from '@/api/services/app';
import { useEffect } from 'react';

type PartnerSignFormProps = React.HTMLAttributes<HTMLDivElement>;

export function RegisterFormStep3({ className, ...props }: PartnerSignFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const { data: onboardingInformation, isLoading: isLoading } =
    useGetReferencesPartnerQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = sessionStorage.getItem('registerPartnerStep3');
    if (data) {
      try {
        const dataParse = JSON.parse(data) as TRegisterFormStep3;
        form.setValue('entityName', dataParse.entityName);
        form.setValue('businessType', parseInt(String(dataParse.businessType)));
        form.setValue('registrationNumber', dataParse.registrationNumber);
        form.setValue('businessStatus', dataParse.businessStatus);
        form.setValue('proofOfRegistration', dataParse.proofOfRegistration);
      } catch (e) {
        console.error(e);
      }
    }
  }, [isLoading]);

  const form = useForm<TRegisterFormStep3>({
    resolver: zodResolver(ZRegisterFormStep3Schema),
    defaultValues: {
      entityName: '',
      businessType: undefined,
      registrationNumber: '',
      businessStatus: '',
      proofOfRegistration: '',
    },
  });
  const { setValue } = form;

  const onChangeFile = (file: File, type: keyof TRegisterFormStep3) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  const onSubmit = (values: TRegisterFormStep3) => {
    sessionStorage.setItem('registerPartnerStep3', JSON.stringify(values));
    dispatch(setRegisterPartnerStep3(values));

    navigate(`/partner/register/${token}/step4`);
  };

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="entityName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('entity_name')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="businessType"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('business_type')}</FormLabel>
                      <FormControl>
                        <Select onValueChange={field.onChange} value={`${field.value}`}>
                          <SelectTrigger className="w-full">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            {onboardingInformation?.businessTypes.map(businessType => (
                              <SelectItem
                                key={businessType.id}
                                value={`${businessType.id}`}
                              >
                                {businessType.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="registrationNumber"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('registration_number')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="businessStatus"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('business_status')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'businessStatus');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="proofOfRegistration"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('proof_of_registration')}</FormLabel>
                      <FormControl>
                        <Input
                          {...fieldProps}
                          type="file"
                          accept="image/*, application/pdf"
                          title={value}
                          onChange={event => {
                            if (event.target.files) {
                              onChange(event.target.files[0]);
                              onChangeFile(event.target.files[0], 'proofOfRegistration');
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit">{t('next')}</Button>
            <Button
              variant="outline"
              onClick={() => navigate(`/partner/register/${token}/step2`)}
            >
              {t('previous')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
