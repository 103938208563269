import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RegisterFormStep1 } from '@/components/Partner/register-form-step1';
import { Icons } from '@/components/icons';
import { useEffect, useState } from 'react';
import { RegisterFormStep2 } from '@/components/Partner/register-form-step2';
import { RegisterFormStep3 } from '@/components/Partner/register-form-step3';
import { RegisterFormStep4 } from '@/components/Partner/register-form-step4';
import { RegisterFormStep5 } from '@/components/Partner/register-form-step5';
import { RegisterPending } from '@/components/Partner/register-form-pending';
import { useGetCheckValidityMutation } from '@/api/services/Partner/onboarding';
import { useToast } from '@/components/ui/use-toast';
import { ONBOARDING_STATUS_PARTNER } from '@/types/Partner/onboarding';
import Loading from '@/components/loading';

export default function RegisterPartner() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { token } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [getCheckValidity] = useGetCheckValidityMutation();

  useEffect(() => {
    setLoading(true);
    getCheckValidity(token || '')
      .unwrap()
      .then(body => {
        setTimeout(() => {
          if (body.status === ONBOARDING_STATUS_PARTNER.USED) {
            navigate(`/partner/register/${token}/pending`);
          } else if (body.status === ONBOARDING_STATUS_PARTNER.APPROVED) {
            toast({
              variant: 'success',
              title: t('account_validated'),
            });
            navigate(`/partner/login`);
          } else if (body.status === ONBOARDING_STATUS_PARTNER.EXPIRED) {
            toast({
              variant: 'destructive',
              title: t('token_expired'),
            });
            navigate('/partner/login');
          } else if (body.status === ONBOARDING_STATUS_PARTNER.REJECTED) {
            toast({
              variant: 'destructive',
              title: t('account_rejected'),
            });
            navigate('/partner/login');
          }
          setLoading(false);
        }, 1000);
      })
      .catch(err => {
        setTimeout(() => {
          setLoading(false);
          console.error(err);
          navigate('/?error=unauthorized');
          toast({
            variant: 'destructive',
            title: t('an_error_occurred'),
          });
        }, 1000);
      });
  }, []);

  return (
    <>
      <div className={`${loading ? 'opacity-10' : ''} w-full grid lg:grid-cols-2 h-dvh`}>
        <div className="flex items-center justify-center py-12">
          <div className="absolute top-8 left-auto">
            <Icons.logo style={{ width: '140px' }} />
          </div>
          <div className="mx-auto grid lg:w-[400px] w-[350px] pt-12 gap-6">
            {!location.pathname.includes('pending') && (
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">{t('register.partner.title')}</h1>
                <p
                  className="text-muted-foreground"
                  dangerouslySetInnerHTML={{
                    __html: t('register.partner.description', {
                      step: location.pathname.includes('step1')
                        ? 1
                        : location.pathname.includes('step2')
                          ? 2
                          : location.pathname.includes('step3')
                            ? 3
                            : location.pathname.includes('step4')
                              ? 4
                              : location.pathname.includes('step5')
                                ? 5
                                : 0,
                    }),
                  }}
                ></p>
              </div>
            )}
            {location.pathname.includes('step1') && <RegisterFormStep1 />}
            {location.pathname.includes('step2') && <RegisterFormStep2 />}
            {location.pathname.includes('step3') && <RegisterFormStep3 />}
            {location.pathname.includes('step4') && <RegisterFormStep4 />}
            {location.pathname.includes('step5') && <RegisterFormStep5 />}
            {location.pathname.includes('pending') && <RegisterPending />}
          </div>
        </div>
        <div
          className={`hidden lg:block bg-muted align-middle ${location.pathname.includes('pending') && "bg-cover bg-[url('/backgroundPartnerLogin.png')]"}`}
        >
          {location.pathname.includes('step1') && (
            <Icons.userResearch style={{ width: '100%' }} className="hidden lg:block" />
          )}
          {location.pathname.includes('step2') && (
            <Icons.userResearch style={{ width: '100%' }} className="hidden lg:block" />
          )}
          {location.pathname.includes('step3') && (
            <Icons.userResearch style={{ width: '100%' }} className="hidden lg:block" />
          )}
          {location.pathname.includes('step4') && (
            <Icons.walletOnboarding
              style={{ width: '100%' }}
              className="hidden lg:block"
            />
          )}
          {location.pathname.includes('step5') && (
            <Icons.internationalTrade
              style={{ width: '100%' }}
              className="hidden lg:block"
            />
          )}
        </div>
      </div>
      {loading && <Loading title={t('loading')} />}
    </>
  );
}
