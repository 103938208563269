import { useTranslation } from 'react-i18next';
import { useToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Dispatch, SetStateAction } from 'react';
import { TOffers } from '@/types/Seller/offers';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { TAuthenticateForm, ZAuthenticateForm } from '@/types/Seller/authenticate';
import { Textarea } from '@/components/ui/textarea';
import {
  usePostFinalizeOfferPartnerMutation,
  usePostRejectOfferPartnerMutation,
} from '@/api/services/Partner/offers';
import { Loader2 } from 'lucide-react';

export default function AuthenticateForm({
  offer,
  setOpenDialog,
}: {
  offer: TOffers;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const [postFinalise, { isLoading: isLoadingFinalize }] =
    usePostFinalizeOfferPartnerMutation();
  const [postReject, { isLoading: isLoadingReject }] =
    usePostRejectOfferPartnerMutation();
  const { toast } = useToast();

  const form = useForm<TAuthenticateForm>({
    resolver: zodResolver(ZAuthenticateForm),
    defaultValues: {
      isValid: 'yes',
      description: '',
      firstPicture: '',
      secondPicture: '',
      thirdPicture: '',
    },
  });

  const { watch, setValue } = form;

  const onChangeFile = (file: File, type: keyof TAuthenticateForm) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue(type, reader.result as string);
    };
    reader.onerror = error => {
      console.error('Error: ', error);
    };
  };

  const onSubmit = (values: TAuthenticateForm) => {
    if (values.isValid === 'yes') {
      postFinalise(offer.id)
        .unwrap()
        .then(() => {
          toast({
            title: t('offer_finalized'),
            variant: 'success',
          });
          setOpenDialog(false);
        })
        .catch(err => {
          console.error(err);
          toast({
            variant: 'destructive',
            title: t('an_error_occurred'),
          });
        });
    } else {
      postReject({
        id: offer.id,
        body: {
          description: values.description || '',
          firstPicture: values.firstPicture || '',
          secondPicture: values.secondPicture || '',
          thirdPicture: values.thirdPicture || '',
        },
      })
        .unwrap()
        .then(() => {
          toast({
            title: t('offer_rejected'),
          });
          setOpenDialog(false);
        })
        .catch(err => {
          console.error(err);
          toast({
            variant: 'destructive',
            title: t('an_error_occurred'),
          });
        });
    }
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="isValid"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>{t('authenticate_message')}</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={`${field.value}`}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="yes" />
                          </FormControl>
                          <FormLabel className="font-normal">{t('yes')}</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="no" />
                          </FormControl>
                          <FormLabel className="font-normal">{t('no')}</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {watch('isValid') === 'no' && (
              <>
                <div>
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem className="space-y-3">
                        <FormLabel>{t('reason')}</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {['first', 'second', 'third'].map((key, index) => (
                  <div key={`picture${index}`}>
                    <FormField
                      control={form.control}
                      name={`${key}Picture` as keyof TAuthenticateForm}
                      render={({ field: { value, onChange, ...fieldProps } }) => (
                        <FormItem>
                          <div className="grid gap-1">
                            <FormLabel>{t(`${key}_picture`)}</FormLabel>
                            <FormControl>
                              <Input
                                {...fieldProps}
                                type="file"
                                accept="image/*, application/pdf"
                                title={value}
                                onChange={event => {
                                  if (event.target.files) {
                                    onChange(event.target.files[0]);
                                    onChangeFile(
                                      event.target.files[0],
                                      `${key}Picture` as keyof TAuthenticateForm,
                                    );
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <br />
          {isLoadingFinalize || isLoadingReject ? (
            <Button disabled className="w-full">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('submit')}
            </Button>
          ) : (
            <>
              <Button type="submit" className="w-full">
                {t('submit')}
              </Button>
            </>
          )}
        </form>
      </Form>
      <Button
        variant="outline"
        className="w-full mt-2"
        onClick={() => setOpenDialog(false)}
      >
        {t('cancel')}
      </Button>
    </>
  );
}
