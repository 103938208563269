import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TCashInForm, ZCashInForm } from '@/types/Partner/cash-in';
import { usePostCashInPartnerMutation } from '@/api/services/Partner/bank';
import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';

export default function CashInForm({
  setOpenDialog,
}: {
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [postCashIn, { isLoading: isLoadingCash }] = usePostCashInPartnerMutation();
  const form = useForm<TCashInForm>({
    resolver: zodResolver(ZCashInForm),
    defaultValues: {
      amount: 1,
    },
  });

  const onSubmit = (values: TCashInForm) => {
    postCashIn(values)
      .unwrap()
      .then(resp => {
        setOpenDialog(false);
        window.open(resp.redirect_url, '_self');
      })
      .catch(err => {
        console.error(err);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <div className="grid gap-1">
                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </div>
                </FormItem>
              )}
            />
          </div>
          {isLoadingCash ? (
            <Button disabled>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t('validate')}
            </Button>
          ) : (
            <>
              <Button type="submit">{t('validate')}</Button>
            </>
          )}
        </div>
      </form>
    </Form>
  );
}
