import { api } from '../api';
import {
  ITempOnboardingForm,
  OnboardingInformation,
  TOnboardingForm,
  TPreOnboardingForm,
} from '@/types/Seller/onboarding';

export const RegisterApiSeller = api.injectEndpoints({
  endpoints: build => ({
    getReferencesSeller: build.query<OnboardingInformation, void>({
      query: () => ({ url: 'public/references' }),
    }),
    postOnboardingSeller: build.mutation<void, TPreOnboardingForm>({
      query: body => ({
        url: 'seller/onboarding',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
    postKycSeller: build.mutation<void, TOnboardingForm>({
      query: body => ({
        url: 'seller/kyc',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
    getKycSeller: build.query<ITempOnboardingForm, void>({
      query: () => ({
        url: 'seller/kyc',
      }),
    }),
  }),
});

export const {
  useGetReferencesSellerQuery,
  usePostOnboardingSellerMutation,
  usePostKycSellerMutation,
  useGetKycSellerQuery,
} = RegisterApiSeller;
