import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { AddItemForm } from '@/components/Seller/add-item-form';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { HitType } from '@/types/Seller/inventory';
import Search from '@/components/search';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';

export default function useAddItem() {
  const [openDialog, setOpenDialog] = useState(false);
  const [itemSelected, setItemSelected] = useState<HitType>();
  const { t } = useTranslation();
  const { data: me } = useGetMeSellerQuery();

  useEffect(() => {
    if (!openDialog) {
      setTimeout(() => {
        setItemSelected(undefined);
      }, 500);
    }
  }, [openDialog]);

  return (
    <Dialog open={openDialog} onOpenChange={setOpenDialog}>
      <DialogTrigger disabled={me?.kycStatus !== ONBOARDING_STATUS_SELLER.APPROVED}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                variant="primary"
                className="mr-2"
                disabled={me?.kycStatus !== ONBOARDING_STATUS_SELLER.APPROVED}
              >
                <Plus size={20} className="mr-2 h-4 w-4" />
                {t('add_article')}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t('finalize_account')}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent className="lg:min-w-[800px]">
        {!itemSelected && (
          <DialogHeader>
            <DialogTitle>
              {t('popular_items')}
              <br />
              <small>{t('trending_items')}</small>
            </DialogTitle>
          </DialogHeader>
        )}
        {!itemSelected ? (
          <>
            <div className="pt-3">
              <Search setItemSelected={setItemSelected} />
            </div>
          </>
        ) : (
          <>
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2">
              <div>
                <img
                  src={`${itemSelected.thumbnail_url}`}
                  className="object-scale-down w-20 lg:w-auto p-3"
                  alt={`${itemSelected.name}`}
                />
              </div>
              <div>
                <p className="text-xl font-bold">{`${itemSelected.name}`}</p>
                <p>{`${itemSelected.ticker_symbol}`}</p>
                <AddItemForm
                  itemSelected={itemSelected}
                  setItemSelected={setItemSelected}
                  setOpenDialog={setOpenDialog}
                />
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
