import { api } from '../api';

export const TrackingPartnerApi = api.injectEndpoints({
  endpoints: build => ({
    postTrackingSeller: build.mutation<
      void,
      {
        id: number;
        body: {
          trackingUrl: string;
        };
      }
    >({
      query: param => ({
        url: `seller/offer/${param.id}/shipping`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: ['OffersSeller', 'OffersKPISeller'],
    }),
  }),
});

export const { usePostTrackingSellerMutation } = TrackingPartnerApi;
