import { useTranslation } from 'react-i18next';
import { Icons } from '@/components/icons';
import { RegisterForm } from '@/components/Seller/register-form';

export default function RegisterSeller() {
  const { t } = useTranslation();

  return (
    <>
      <div className={`w-full grid lg:grid-cols-2 h-dvh`}>
        <div className="flex items-center justify-center py-12">
          <div className="absolute top-8 left-auto">
            <Icons.logo style={{ width: '140px' }} />
          </div>
          <div className="mx-auto grid lg:w-[400px] w-[350px] pt-12 gap-6">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-3xl font-bold tracking-tight">
                {t('register.seller.title')}
              </h1>
              <p className="text-md text-muted-foreground">
                {t('register.seller.description')}
              </p>
            </div>
            <RegisterForm />
          </div>
        </div>
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <Icons.userResearch style={{ width: 'auto' }} />
        </div>
      </div>
    </>
  );
}
