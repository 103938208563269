import { z } from 'zod';

export const ZTrackingForm = z.object({
  trackingUrl: z
    .string()
    .regex(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
      {
        message: 'url_invalid',
      },
    ),
});

export type TTrackingForm = z.infer<typeof ZTrackingForm>;
