import { z } from 'zod';
import { ZInventorySchema } from '@/types/Seller/inventory';

let oldPrice = 0;

export enum OFFER_STATUS {
  'SELLER_PENDING_RESPONSE' = 'SELLER_PENDING_RESPONSE',
  'PARTNER_PENDING_RESPONSE' = 'PARTNER_PENDING_RESPONSE',
  'SELLER_REFUSED' = 'SELLER_REFUSED',
  'SELLER_ACCEPTED' = 'SELLER_ACCEPTED',
  'PARTNER_ACCEPTED' = 'PARTNER_ACCEPTED',
  'PARTNER_REFUSED' = 'PARTNER_REFUSED',
  'SELLER_WAITING_SHIPPING' = 'SELLER_WAITING_SHIPPING',
  'SELLER_SHIPPED' = 'SELLER_SHIPPED',
  'ITEM_AUTHENTICATED_FAILED' = 'ITEM_AUTHENTICATED_FAILED',
  'ITEM_AUTHENTICATED_SUCCESS' = 'ITEM_AUTHENTICATED_SUCCESS',
}

export const ZStatisticsSchema = z.object({
  inTransitOffers: z.number(),
  inTransitValue: z.number(),
  pendingOffers: z.number(),
  averageAuthenticatedSaleValue: z.number(),
});

export const ZProposal = z.object({
  price: z.number(),
  initiator: z.string(),
  createdAt: z.string(),
});

export const ZCounterOfferForm = z.object({
  id: z.number().optional(),
  oldPrice: z.coerce.number().refine(value => {
    oldPrice = value;
    return true;
  }),
  newPrice: z.coerce
    .number()
    .refine(value => value !== oldPrice, 'counter_offer_price_same'),
});

export const ZCounterOfferPost = z.object({
  id: z.number(),
  price: z.number(),
});

export const ZOffers = z
  .object({
    status: z.nativeEnum(OFFER_STATUS),
    proposals: z.array(ZProposal),
    maxCounterOffer: z.number(),
    trackingUrl: z.string().optional(),
  })
  .merge(ZInventorySchema);

export type TOffers = z.infer<typeof ZOffers>;
export type TProposal = z.infer<typeof ZProposal>;
export type TCounterOfferPost = z.infer<typeof ZCounterOfferPost>;
export type TCounterOfferForm = z.infer<typeof ZCounterOfferForm>;
export type TOffersStatisticsSeller = z.infer<typeof ZStatisticsSchema>;
