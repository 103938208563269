import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { format, parseISO } from 'date-fns';
import { formattedCurrency, getLocale } from '@/lib/utils';
import { TOffers } from '@/types/Seller/offers';
import { useTranslation } from 'react-i18next';

export default function priceHistoryTable({
  article,
  light,
}: {
  article: TOffers;
  light?: boolean;
}) {
  const { t } = useTranslation();
  return light ? (
    <>
      {article.proposals.map((proposal, key) => {
        return (
          <div className="flex-row flex gap-2" key={`history${key}`}>
            <div className="line-through">
              {formattedCurrency(
                article.proposals[key - 1]
                  ? article.proposals[key - 1]?.price
                  : article.listPrice,
              )}
            </div>
            <div>
              <span className="pl-1">{formattedCurrency(proposal.price)}</span>
            </div>
          </div>
        );
      })}
    </>
  ) : (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t('date')}</TableHead>
            <TableHead>{t('price')}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {article.proposals.map((proposal, key) => {
            return (
              <>
                <TableRow key={`row${key}`}>
                  <TableCell>
                    {format(parseISO(proposal.createdAt), 'dd/MM/yyyy H:mm', {
                      locale: getLocale(),
                    })}
                  </TableCell>
                  <TableCell className="flex flex-row text-right">
                    <div className="line-through">
                      {formattedCurrency(
                        article.proposals[key - 1]
                          ? article.proposals[key - 1]?.price
                          : article.listPrice,
                      )}
                    </div>
                    <div>
                      <span className="pl-1">{formattedCurrency(proposal.price)}</span>
                    </div>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
