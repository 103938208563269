import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  TPersonalInformationForm,
  TUpdatePasswordForm,
  ZPersonalInformationFormSchema,
  ZUpdatePasswordFormSchema,
} from '@/types/Partner/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  useGetMePartnerQuery,
  usePutPasswordPartnerMutation,
} from '@/api/services/Partner/me';
import { PasswordField } from '@/components/ui/password-field';
import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';

export function PersonalInformationFormPartner() {
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const { toast } = useToast();

  const { data: personalInformation } = useGetMePartnerQuery();
  const [putPassword, { isLoading: isLoadingPutPassword }] =
    usePutPasswordPartnerMutation();

  const form = useForm<TPersonalInformationForm>({
    resolver: zodResolver(ZPersonalInformationFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      fakePassword: '',
      password: '',
    },
  });

  const editPasswordForm = useForm<TUpdatePasswordForm>({
    resolver: zodResolver(ZUpdatePasswordFormSchema),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = (values: TUpdatePasswordForm) => {
    putPassword({
      password: values.password,
    })
      .unwrap()
      .then(() => {
        setDisplayPassword(false);
        toast({
          variant: 'success',
          title: t('update_password_successfully'),
        });
      })
      .catch(err => {
        console.error(err);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={() => {}} autoComplete="off">
          <div className="mb-6">
            <div>
              <h3 className="text-xl font-medium">{t('personal_information')}</h3>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('firstname')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.firstName || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('lastname')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.lastName || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('email')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.email || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="fakePassword"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <div className="flex flex-row justify-between">
                        <div>
                          <FormLabel>{t('password')}</FormLabel>
                        </div>
                        <div>
                          <Link
                            to={''}
                            onClick={() => setDisplayPassword(!displayPassword)}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            {t('edit')}
                          </Link>
                        </div>
                      </div>
                      <FormControl>
                        <Input
                          type="password"
                          {...field}
                          value={personalInformation?.email || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
      <Form {...editPasswordForm}>
        <form onSubmit={editPasswordForm.handleSubmit(onSubmit)} autoComplete="off">
          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className={displayPassword ? '' : 'hidden'}>
              <FormField
                control={editPasswordForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('password')}</FormLabel>
                      <FormControl>
                        <PasswordField {...field} />
                      </FormControl>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div className="grid md:grid-cols-3 gap-1">
              {isLoadingPutPassword ? (
                <Button disabled>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {t('save_modifications')}
                </Button>
              ) : (
                <>
                  <Button type="submit" disabled={!displayPassword}>
                    {t('save_modifications')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
