import { navItemsPartner } from '@/constants/data';
import { useTranslation } from 'react-i18next';
import SidebarDesktopPartner from '@/components/Partner/layout/sidebar-desktop-partner';
import HeaderPartner from '@/components/Partner/layout/header-partner';
import { TableDemands } from '@/components/Partner/table-request';
import { useGetRequestPartnerQuery } from '@/api/services/Partner/request';

export default function RequestsPartner() {
  const { t } = useTranslation();

  const { data: requests } = useGetRequestPartnerQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  return (
    <>
      <div className="lg:grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopPartner navItems={navItemsPartner} title="" />
        <div className="flex flex-col">
          <HeaderPartner
            navItems={navItemsPartner}
            title={t('my_demands')}
            type="dashboard"
          />
          <main className="p-6">{<TableDemands data={requests || []} />}</main>
        </div>
      </div>
    </>
  );
}
