import { NavItem } from '@/types';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Menu } from 'lucide-react';
import { MenuItemsSidebar } from '@/components/layouts/menu-items-sidebar';
import { DropdownHeader } from '@/components/Seller/layout/dropdown-header';
import { Icons } from '@/components/icons';
import { Link } from 'react-router-dom';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { cn } from '@/lib/utils';
import { useState } from 'react';
import Banner from '@/components/Seller/layout/banner';
import { naveItemsProfileSeller } from '@/constants/data';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';
import BankSoldSeller from '@/components/Seller/layout/bank-sold-seller';

export default function Header({
  navItems,
  title,
  type,
}: {
  navItems: NavItem[];
  title: string;
  type: 'profile' | 'dashboard';
}) {
  const [sheetOpen, setSheetOpen] = useState(false);
  const { t } = useTranslation();
  const { data: me } = useGetMeSellerQuery();

  return (
    <>
      <header className="flex h-14 items-center gap-4 px-4 lg:h-[60px] lg:px-6">
        <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" className="shrink-0 lg:hidden">
              <Menu className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="flex flex-col">
            <nav className="mt-5 grid gap-2 text-lg font-medium">
              <MenuItemsSidebar
                items={navItems}
                open={sheetOpen}
                setOpen={setSheetOpen}
              />
            </nav>
            <div className="mt-auto">
              <Banner />
              <BankSoldSeller />
            </div>
          </SheetContent>
        </Sheet>
        <div
          className={cn('w-full flex flex-1 justify-center lg:justify-start', {
            'lg:justify-center':
              type === 'profile' && me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED,
          })}
        >
          {title && (
            <div className="hidden lg:block">
              <h1 className="text-2xl mt-4 font-semibold tracking-tight">{title}</h1>
            </div>
          )}
          {type === 'profile' && me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED && (
            <div className="hidden lg:block rounded">
              {naveItemsProfileSeller.map((item, index) => (
                <Button
                  variant={location.pathname === item.href ? 'primary' : 'ghost'}
                  className="mr-5 rounded-2xl"
                  key={index}
                  asChild
                >
                  <Link to={`${item.href}`}>{t(item.title)}</Link>
                </Button>
              ))}
            </div>
          )}
          <div className="block lg:hidden">
            <Link to="/dashboard">
              <Icons.logo style={{ width: '130px' }} />
            </Link>
          </div>
        </div>
        <DropdownHeader />
      </header>
    </>
  );
}
