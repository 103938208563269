import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useToast } from '@/components/ui/use-toast';
import { usePostCashOutSellerMutation } from '@/api/services/Seller/bank';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { TCashOutForm, ZCashOutForm } from '@/types/Seller/cash-out';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { Loader2 } from 'lucide-react';

export default function CashOutForm({
  setOpenDialog,
}: {
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}) {
  const { data } = useGetMeSellerQuery();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);

  const { t } = useTranslation();
  const { toast } = useToast();
  const [postCashOut, { isLoading: isLoadingCashOut }] = usePostCashOutSellerMutation();
  const form = useForm<TCashOutForm>({
    resolver: zodResolver(ZCashOutForm),
    defaultValues: {
      amount: 1,
    },
  });

  const onSubmit = (values: TCashOutForm) => {
    if (data && values.amount > data.cashOutBalance) {
      form.setError('amount', { message: 'max_amount_cash_out' });
    } else {
      setAmount(values.amount);
      setOpenConfirm(true);
    }
  };

  const cashOut = async () => {
    await postCashOut({ amount: amount })
      .unwrap()
      .then(() => {
        setOpenDialog(false);
        setOpenConfirm(false);
        toast({
          variant: 'success',
          title: t('cash_out_successfully', { amount: amount }),
        });
      })
      .catch(err => {
        console.error(err);
        toast({
          variant: 'destructive',
          title: t('an_error_occurred'),
        });
      });
  };

  return (
    <>
      <AlertDialog open={openConfirm} onOpenChange={setOpenConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('confirm_cash_out', { amount: amount })}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>{t('confirm_cash_out_message')}</AlertDialogDescription>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                setOpenConfirm(false);
              }}
            >
              {t('cancel')}
            </Button>
            {isLoadingCashOut ? (
              <Button disabled variant="outline">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t('confirm')}
              </Button>
            ) : (
              <Button variant="outline" onClick={() => cashOut()}>
                {t('confirm')}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormControl>
                        <Input type="number" {...field} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            {isLoadingCashOut ? (
              <Button disabled>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t('validate')}
              </Button>
            ) : (
              <Button type="submit">{t('validate')}</Button>
            )}
          </div>
        </form>
      </Form>
    </>
  );
}
