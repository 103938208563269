import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Hourglass } from 'lucide-react';
import { Button } from '@/components/ui/button';

export function RegisterPending() {
  const { t } = useTranslation();

  return (
    <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
      <Hourglass className="h-10 w-10 text-orange-900" />
      <h1 className="text-3xl font-bold leading-tight">{t('onboarding.pending')}</h1>
      <p className="text-center text-muted-foreground">
        {t('onboarding.pending_description')}
      </p>
      <div className="mt-4">
        <Link to="" onClick={() => location.reload()}>
          <Button className="bg-indigo-700 hover:bg-indigo-500">{t('refresh')}</Button>
        </Link>
      </div>
    </div>
  );
}
