import { navItemsPartner } from '@/constants/data';
import { useTranslation } from 'react-i18next';
import SidebarDesktopPartner from '@/components/Partner/layout/sidebar-desktop-partner';
import HeaderPartner from '@/components/Partner/layout/header-partner';
import { OffersTable } from '@/components/Partner/table-offers';
import { useGetOffersPartnerQuery } from '@/api/services/Partner/offers';
import OffersInsightCard from '@/components/Partner/offers-insight-card';

export default function RequestsPartner() {
  const { t } = useTranslation();

  const { data: offers } = useGetOffersPartnerQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <div className="lg:grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopPartner navItems={navItemsPartner} title="" />
        <div className="flex flex-col">
          <HeaderPartner
            navItems={navItemsPartner}
            title={t('available_offers')}
            type="dashboard"
          />
          <main className="p-6">
            <div className="space-y-4">
              <OffersInsightCard />
              <OffersTable data={offers || []} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
