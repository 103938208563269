import { PieChart, Wallet } from 'lucide-react';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import { cn } from '@/lib/utils';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import CashOut from '@/components/Seller/cash-out';
import { useState } from 'react';

export default function BankSoldSeller() {
  const [openCashOut, setOpenOpenCashOut] = useState(false);

  const { t } = useTranslation();
  const { data: me } = useGetMeSellerQuery();

  return me?.kycStatus && [ONBOARDING_STATUS_SELLER.APPROVED].includes(me.kycStatus) ? (
    <div className="flex flex-col space-y-3 cursor-pointer">
      <CashOut isOpenDialog={openCashOut} setOpenDialog={setOpenOpenCashOut} />
      <div
        className={cn('ml-3 gap-4 flex items-center font-bold')}
        onClick={() => setOpenOpenCashOut(true)}
      >
        <div>
          <Button variant="secondary" size="icon">
            <Wallet className="h-5 w-5" />
          </Button>
        </div>
        <div>
          {t('total_balance')} :{' '}
          {me ? `${me.cashOutBalance}€` : <Skeleton className="ml-2 w-[50px]" />}
        </div>
      </div>
      <div className={cn('ml-3 gap-4 flex items-center')}>
        <div>
          <Button variant="secondary" size="icon">
            <PieChart className="h-5 w-5" />
          </Button>
        </div>
        <div>
          {t('limit_threshold')} :<br />{' '}
          {me ? (
            `${me.threshold ? me.threshold : 0}€ / 5000€`
          ) : (
            <Skeleton className="ml-2 w-[50px]" />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
