import { FEES } from '@/config/app';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TAddItemForm, TItem } from '@/types/Seller/inventory';

export default function Fees({
  formValues,
  sellerFees,
}: {
  formValues: TAddItemForm;
  sellerFees: number;
}) {
  const [totalFees, setTotalFees] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    setTotal(formValues.products.reduce((a, b: TItem) => a + Number(b.price), 0));
    setTotalFees(Math.round((((sellerFees + FEES) * total) / 100) * 10) / 10);
  }, [formValues, total]);

  return (
    <>
      <div className="flex flex-col border rounded-xl mt-4 text-sm">
        <div className="flex flex-row justify-between border-b p-2">
          <div>{`${t('fees')} (${sellerFees + FEES}%)`}</div>
          <div>-{totalFees}€</div>
        </div>
        <div className="flex flex-row justify-between p-2">
          <div>{t('total')}</div>
          <div>{total - totalFees}€</div>
        </div>
      </div>
    </>
  );
}
