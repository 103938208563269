import { OnboardingForm } from '@/components/Seller/onboarding-form';
import { useGetMeSellerQuery } from '@/api/services/Seller/me';
import OnboardingPending from '@/components/Seller/onboarding-pending';
import OnboardingApproved from '@/components/Seller/onboarding-approved';
import OnboardingStepper from '@/components/Seller/onboarding-stepper';
import { Skeleton } from '@/components/ui/skeleton';
import { ONBOARDING_STATUS_SELLER } from '@/types/Seller/onboarding';

export default function OnboardingSeller() {
  const { data: me } = useGetMeSellerQuery();

  return (
    <>
      {!me && (
        <>
          {[0, 1].map(key => {
            return (
              <div key={key}>
                <Skeleton className="h-8 w-[350px] mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
                <Skeleton className="h-8 w-full mb-3" />
              </div>
            );
          })}
        </>
      )}
      {me?.kycStatus &&
        [
          ONBOARDING_STATUS_SELLER.NOT_STARTED,
          ONBOARDING_STATUS_SELLER.REJECTED,
        ].includes(me?.kycStatus) && (
          <>
            <OnboardingStepper />
            {me?.kycStatus === ONBOARDING_STATUS_SELLER.REJECTED && (
              <div className="space-y-1 pt-5 mb-3">
                <h4 className="text-sm font-bold leading-none text-destructive">
                  Détails de l&apos;erreur
                </h4>
                <p className="text-sm text-destructive">
                  Votre RIB est invalide. Veuillez vérifier les informations saisies.
                </p>
              </div>
            )}
            <OnboardingForm />
          </>
        )}
      {me?.kycStatus === ONBOARDING_STATUS_SELLER.PENDING && (
        <>
          <OnboardingStepper />
          <OnboardingPending />
        </>
      )}
      {me?.kycStatus === ONBOARDING_STATUS_SELLER.APPROVED && (
        <>
          <OnboardingApproved />
        </>
      )}
    </>
  );
}
