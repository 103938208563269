import { api } from '../api';
import {
  TCheckEmailBody,
  TokenValidity,
  TRegistrationForm,
} from '@/types/Partner/onboarding';
import { OnboardingInformation } from '@/types/Seller/onboarding';

export const RegisterApiPartner = api.injectEndpoints({
  endpoints: build => ({
    getCheckValidity: build.mutation<TokenValidity, string>({
      query: token => ({ url: `partner/registration?token=${token}` }),
    }),
    getReferencesPartner: build.query<OnboardingInformation, void>({
      query: () => ({ url: 'public/references' }),
    }),
    postCheckEmail: build.mutation<void, TCheckEmailBody>({
      query: body => ({
        url: 'partner/registration/email',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
    postOnboarding: build.mutation<void, TRegistrationForm>({
      query: body => ({
        url: 'partner/registration',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Me'],
    }),
  }),
});

export const {
  useGetCheckValidityMutation,
  useGetReferencesPartnerQuery,
  usePostCheckEmailMutation,
  usePostOnboardingMutation,
} = RegisterApiPartner;
