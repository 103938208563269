import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import NoMatch from './pages/NoMatch';
import Empty from './pages/Empty';
import Sample from './pages/Sample';
import LoginPartner from '@/pages/Partner/LoginPartner';
import RegisterPartner from '@/pages/Partner/RegisterPartner';
import ProfileLayoutSeller from '@/pages/Seller/ProfileLayoutSeller';
import OnboardingSeller from '@/pages/Seller/OnboardingSeller';
import { ProtectedRoute } from '@/pages/ProtectedRoute';
import PersonalInformationSeller from '@/pages/Seller/PersonalInformationSeller';
import BankInformationSeller from '@/pages/Seller/BankInformationSeller';
import { AddressForm } from '@/components/Seller/address-form';
import LoginSeller from '@/pages/Seller/LoginSeller';
import RegisterSeller from '@/pages/Seller/RegisterSeller';
import { Legal } from '@/pages/Legal';
import ResetPasswordPartner from '@/pages/Partner/ResetPasswordPartner';
import ListingSeller from '@/pages/Seller/ListingSeller';
import OffersSeller from '@/pages/Seller/OffersSeller';
import Shops from '@/pages/Seller/Shops';
import ProfileLayoutPartner from '@/pages/Partner/ProfileLayoutPartner';
import PersonalInformationPartner from '@/pages/Partner/PersonalInformationPartner';
import RequestsPartner from '@/pages/Partner/RequestsPartner';
import OffersPartner from '@/pages/Partner/OffersPartner';
import { AddressFormPartner } from '@/components/Partner/address-form-partner';
import BankInformationPartner from '@/pages/Partner/BankInformationPartner';

const Routes = () => {
  const routesForPublic = [
    {
      path: 'test',
      element: <div>Test Page</div>,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: 'dashboard',
          element: <ListingSeller />,
        },
        {
          path: 'listing',
          element: <ListingSeller />,
        },
        {
          path: 'offers',
          element: <OffersSeller />,
        },
        {
          path: 'shops',
          element: <Shops />,
        },
        {
          path: 'profile',
          element: (
            <ProfileLayoutSeller>
              <OnboardingSeller />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'onboarding',
          element: (
            <ProfileLayoutSeller>
              <OnboardingSeller />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'personal-information',
          element: (
            <ProfileLayoutSeller>
              <PersonalInformationSeller />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'bank-information',
          element: (
            <ProfileLayoutSeller>
              <BankInformationSeller />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'address',
          element: (
            <ProfileLayoutSeller>
              <AddressForm />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'legal',
          element: (
            <ProfileLayoutSeller>
              <Legal />
            </ProfileLayoutSeller>
          ),
        },
        {
          path: 'sample',
          element: <Sample />,
        },
        {
          path: 'empty',
          element: <Empty />,
        },
        {
          path: 'partner/dashboard',
          element: <RequestsPartner />,
        },
        {
          path: 'partner/cash-in',
          element: <RequestsPartner />,
        },
        {
          path: 'partner/demands',
          element: <RequestsPartner />,
        },
        {
          path: 'partner/offers',
          element: <OffersPartner />,
        },
        {
          path: 'partner/personal-information',
          element: (
            <ProfileLayoutPartner>
              <PersonalInformationPartner />
            </ProfileLayoutPartner>
          ),
        },
        {
          path: 'partner/bank-information',
          element: (
            <ProfileLayoutPartner>{<BankInformationPartner />}</ProfileLayoutPartner>
          ),
        },
        {
          path: 'partner/address',
          element: <ProfileLayoutPartner>{<AddressFormPartner />}</ProfileLayoutPartner>,
        },
        {
          path: 'partner/legal',
          element: <ProfileLayoutPartner>{<Legal />}</ProfileLayoutPartner>,
        },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly = [
    {
      path: '/',
      element: <LoginSeller />,
    },
    {
      path: 'register',
      element: <RegisterSeller />,
    },
    {
      path: 'partner/login',
      element: <LoginPartner />,
    },
    {
      path: 'partner/reset-password',
      element: <ResetPasswordPartner />,
    },
    {
      path: 'partner/check',
      element: <ResetPasswordPartner />,
    },
    {
      path: 'partner/register/:token/step1',
      element: <RegisterPartner />,
    },
    {
      path: 'partner/register/:token/step2',
      element: <RegisterPartner />,
    },
    {
      path: 'partner/register/:token/step3',
      element: <RegisterPartner />,
    },
    {
      path: 'partner/register/:token/step4',
      element: <RegisterPartner />,
    },
    {
      path: 'partner/register/:token/step5',
      element: <RegisterPartner />,
    },
    {
      path: 'partner/register/:token/pending',
      element: <RegisterPartner />,
    },
    {
      path: '*',
      element: <NoMatch />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
