import { CircleCheck } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export default function OnboardingApproved() {
  const { t } = useTranslation();
  return (
    <>
      <div className="m-auto flex h-full w-full flex-col items-center justify-center gap-2">
        <CircleCheck className="w-10 h-10" />
        <h1 className="text-3xl font-bold leading-tight">
          {t('onboarding.step3_title')}
        </h1>
        <p className="text-center text-muted-foreground">
          {t('onboarding.approved_description')}
        </p>
        <div className="mt-4">
          <Link to="/dashboard">
            <Button className="bg-indigo-700 hover:bg-indigo-500">
              {t('dashboard')}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
