import { navItemsPartner } from '@/constants/data';
import HeaderPartner from '@/components/Partner/layout/header-partner';
import SidebarDesktopPartner from '@/components/Partner/layout/sidebar-desktop-partner';

export default function ProfileLayoutPartner({
  children,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <>
      <div className="grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopPartner navItems={navItemsPartner} title={''} />
        <div className="flex flex-col">
          <HeaderPartner navItems={navItemsPartner} title={''} type="profile" />
          <main className="flex flex-1 flex-col p-4 lg:p-6">{children}</main>
        </div>
      </div>
    </>
  );
}
