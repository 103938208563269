import { api } from '../api';

export const LoginApiSeller = api.injectEndpoints({
  endpoints: build => ({
    postLogin: build.query<{ token: string }, string>({
      query: code => ({
        url: `seller/login?code=${code}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLazyPostLoginQuery } = LoginApiSeller;
