interface AppConfig {
  name: string;
  github: {
    title: string;
    url: string;
  };
}

export const appConfig: AppConfig = {
  name: 'Scale',
  github: {
    title: 'Scale',
    url: '#',
  },
};

export const FEES = 1.7;
