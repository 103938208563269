import { useTranslation } from 'react-i18next';
import { TOffers } from '@/types/Seller/offers';

export default function StatusBadge({ offer }: { offer: TOffers }) {
  const { t } = useTranslation();
  if (
    offer.status.split('_').includes('REFUSED') ||
    offer.status.split('_').includes('FAILED')
  ) {
    return (
      <span
        key={`${offer.id}${offer.status}`}
        className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 font-medium text-red-700 ring-1 ring-inset ring-red-700/10"
      >
        {t(`offers.status.${offer.status}`)}
      </span>
    );
  } else if (offer.status.split('_').includes('WAITING')) {
    return (
      <span
        key={`${offer.id}${offer.status}`}
        className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 font-medium text-yellow-700 ring-1 ring-inset ring-yellow-700/10"
      >
        {t(`offers.status.${offer.status}`)}
      </span>
    );
  } else if (offer.status.split('_').includes('SUCCESS')) {
    return (
      <span
        key={`${offer.id}${offer.status}`}
        className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-700/10"
      >
        {t(`offers.status.${offer.status}`)}
      </span>
    );
  } else {
    return (
      <span
        key={`${offer.id}${offer.status}`}
        className="inline-flex items-center rounded-md bg-indigo-100 px-2 py-1 font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
      >
        {t(`offers.status.${offer.status}`)}
      </span>
    );
  }
}
