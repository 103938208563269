import { useTranslation } from 'react-i18next';
import CGUV from '@/locales/fr/cgv';

export const Legal = () => {
  const { t } = useTranslation();

  return (
    <div className="mr-2 ml-2 text-black max-w-7xl">
      <h3 className="text-xl font-medium mb-6">{t('legal_information')}</h3>
      <CGUV />
    </div>
  );
};
