import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  OnboardingInformation,
  TPersonalInformationForm,
  ZPersonalInformationFormSchema,
} from '@/types/Seller/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { useDeleteAccountMutation, useGetMeSellerQuery } from '@/api/services/Seller/me';
import { useState } from 'react';

export function PersonalInformationForm() {
  useState<OnboardingInformation>();
  const { t } = useTranslation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: personalInformation } = useGetMeSellerQuery();
  const [deleteAccount] = useDeleteAccountMutation();

  const form = useForm<TPersonalInformationForm>({
    resolver: zodResolver(ZPersonalInformationFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  });

  const onSubmit = () => {};

  const onConfirmDeleteAccount = async () => {
    await deleteAccount()
      .unwrap()
      .then(() => {
        toast({
          title: t('deleted_account_message'),
          variant: 'success',
        });
        localStorage.removeItem('token');
        navigate('/');
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="mb-6">
            <div>
              <h3 className="text-xl font-medium">{t('personal_information')}</h3>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('firstname')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.firstName || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('lastname')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.lastName || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('email')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.email || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('phone')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={personalInformation?.phone || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-1 mt-6">
            <Button type="submit" disabled>
              {t('save_modifications')}
            </Button>
            <div></div>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="destructive">{t('delete_account')}</Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>{t('delete_account_confirmation')}</AlertDialogTitle>
                  <AlertDialogDescription>
                    {t('delete_account_confirmation_description')}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t('cancel')}</AlertDialogCancel>
                  <AlertDialogAction
                    className="bg-destructive"
                    onClick={onConfirmDeleteAccount}
                  >
                    {t('delete_permanently')}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </form>
      </Form>
    </>
  );
}
