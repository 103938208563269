import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  OnboardingInformation,
  TBankInformationForm,
  ZBankInformationFormSchema,
} from '@/types/Seller/onboarding';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Link } from 'react-router-dom';
import {
  useGetBankInformationPartnerQuery,
  useGetMePartnerQuery,
} from '@/api/services/Partner/me';
import { Skeleton } from '@/components/ui/skeleton';
import CashIn from '@/components/Partner/cash-in';
import { useState } from 'react';

export function BankFormPartner() {
  useState<OnboardingInformation>();
  const [openCashIn, setOpenOpenCashIn] = useState(false);

  const { t } = useTranslation();
  const { data: bankInformation } = useGetBankInformationPartnerQuery();
  const { data: me } = useGetMePartnerQuery();

  const form = useForm<TBankInformationForm>({
    resolver: zodResolver(ZBankInformationFormSchema),
    defaultValues: {
      holderName: '',
      bic: '',
      iban: '',
    },
  });

  const onSubmit = () => {};

  return (
    <>
      <CashIn isOpenDialog={openCashIn} setOpenDialog={setOpenOpenCashIn} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          <div className="mb-6">
            <div>
              <h3 className="text-xl font-medium pb-3">{t('payment_methods')}</h3>
              <p>
                {t('your_cash_available')} :{' '}
                <Link
                  to={''}
                  onClick={() => setOpenOpenCashIn(true)}
                  className="text-indigo-500 underline font-bold"
                >
                  {me ? `${me.balance}€` : <Skeleton className="ml-2 w-[50px]" />}
                </Link>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <FormField
                control={form.control}
                name="holderName"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('holder_name')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={bankInformation?.holderName || ''}
                          disabled
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="iban"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('iban')}</FormLabel>
                      <FormControl>
                        <Input {...field} value={bankInformation?.iban || ''} disabled />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="bic"
                render={({ field }) => (
                  <FormItem>
                    <div className="grid gap-1">
                      <FormLabel>{t('bic')}</FormLabel>
                      <FormControl>
                        <Input {...field} value={bankInformation?.bic || ''} disabled />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormDescription>{t('bank_information_info')}</FormDescription>
            </div>
          </div>
          <div className="grid mt-6">
            <Button type="submit" disabled>
              {t('save_modifications')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
