import { useTranslation } from 'react-i18next';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { formattedCurrency } from '@/lib/utils';
import { useGetOffersStatisticsPartnerQuery } from '@/api/services/Partner/offers';

export default function OffersInsightCard() {
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetOffersStatisticsPartnerQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <h2 className="font-semibold">{t('offers_analytics')}</h2>
      <ScrollArea className="whitespace-nowrap">
        <div className="flex space-x-4">
          {isLoading || isFetching ? (
            [1, 2, 3, 4].map((_, index) => (
              <Skeleton className="h-[125px] w-[250px] rounded-xl" key={index} />
            ))
          ) : (
            <>
              <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {data?.inTransitOffers || 0}
                  </h5>
                </a>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {t('inTransitOffers')}
                </p>
              </div>
              <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {formattedCurrency(data?.inTransitValue || 0)}
                  </h5>
                </a>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {t('inTransitValue')}
                </p>
              </div>
              <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {data?.pendingOffers || 0}
                  </h5>
                </a>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {t('pendingOffers')}
                </p>
              </div>
              <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {formattedCurrency(data?.averageAuthenticatedSaleValue || 0)}
                  </h5>
                </a>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {t('averageAuthenticatedSaleValue')}
                </p>
              </div>
            </>
          )}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </>
  );
}
