import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TOffers } from '@/types/Seller/offers';
import { AlertDescription } from '@/components/ui/alert';
import AuthenticateForm from '@/components/Partner/authenticate-form';

interface IProps {
  openAuthenticate: boolean;
  setOpenAuthenticate: React.Dispatch<React.SetStateAction<boolean>>;
  article: TOffers;
}

export default function Authenticate({
  openAuthenticate,
  setOpenAuthenticate,
  article,
}: IProps) {
  const { t } = useTranslation();

  return (
    <AlertDialog open={openAuthenticate}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('authenticate')}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDescription>
          <AuthenticateForm offer={article} setOpenDialog={setOpenAuthenticate} />
        </AlertDescription>
      </AlertDialogContent>
    </AlertDialog>
  );
}
