import { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogContent } from '../ui/dialog';
import { TOffers } from '@/types/Seller/offers';
import { CounterOfferForm } from '@/components/Seller/counter-offer-form';
import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/ui/badge';
import priceHistory from '@/components/price-history-table';

export default function CounterOffer({
  isOpenDialog,
  setOpenDialog,
  article,
}: {
  isOpenDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  article: TOffers;
}) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpenDialog} onOpenChange={setOpenDialog}>
      <DialogContent className="lg:min-w-[800px]">
        {article && (
          <>
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2">
              <div>
                <img
                  src={`${article.imageUrl}`}
                  className="object-scale-down w-20 lg:w-auto p-3"
                  alt={`${article.name}`}
                />
              </div>
              <div>
                <p className="text-xl font-bold">{`${article.name}`}</p>
                <p>{`${article.sku}`}</p>
                <br />
                <p>
                  {t('size')} : <Badge>{`${article.size}`}</Badge>
                </p>
                <br />
                {article.proposals.length <= article.maxCounterOffer &&
                  priceHistory({ article: article, light: false })}
                {article.proposals.length < article.maxCounterOffer ? (
                  <CounterOfferForm
                    itemSelected={article}
                    setOpenDialog={setOpenDialog}
                  />
                ) : (
                  <p className="font-bold text-sm pt-3">
                    {t('max_counter_offer_reached')}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
