import { api } from '../api';
import { TItem } from '@/types/Seller/inventory';
import {
  TCounterOfferPost,
  TOffers,
  TOffersStatisticsSeller,
} from '@/types/Seller/offers';

export const offersSellerApi = api.injectEndpoints({
  endpoints: build => ({
    getOffersSeller: build.query<TOffers[], void>({
      query: () => ({ url: 'seller/offers' }),
      providesTags: ['OffersSeller'],
    }),
    postRequestSeller: build.mutation<[], TItem>({
      query: body => ({
        url: 'seller/offers',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OffersSeller', 'OffersKPISeller'],
    }),
    postRefuseOfferSeller: build.mutation<void, number>({
      query: id => ({
        url: `seller/offer/${id}/refuse`,
        method: 'POST',
      }),
      invalidatesTags: ['OffersSeller', 'OffersKPISeller'],
    }),
    postAcceptOfferSeller: build.mutation<void, number>({
      query: id => ({
        url: `seller/offer/${id}/accept`,
        method: 'POST',
      }),
      invalidatesTags: ['OffersSeller', 'OffersKPISeller'],
    }),
    postNewCounterOfferSeller: build.mutation<void, TCounterOfferPost>({
      query: body => ({
        url: `seller/offer/${body.id}/proposals`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['OffersSeller', 'OffersKPISeller'],
    }),
    getOffersStatisticsSeller: build.query<TOffersStatisticsSeller, void>({
      query: () => ({
        url: `seller/offers-statistics`,
        method: 'GET',
      }),
      providesTags: ['OffersKPISeller'],
    }),
  }),
});

export const {
  useGetOffersSellerQuery,
  usePostAcceptOfferSellerMutation,
  usePostRefuseOfferSellerMutation,
  usePostNewCounterOfferSellerMutation,
  useGetOffersStatisticsSellerQuery,
} = offersSellerApi;
