import { api } from '../api';
import { MeInformation } from '@/types/Seller/me';
import { TAddressFullForm, TBankInformationForm } from '@/types/Seller/onboarding';

export const meSellerApi = api.injectEndpoints({
  endpoints: build => ({
    getMeSeller: build.query<MeInformation, void>({
      query: () => ({ url: 'seller/me' }),
      providesTags: ['Me'],
    }),
    getBankInformation: build.query<TBankInformationForm, void>({
      query: () => ({ url: 'seller/bank-information' }),
    }),
    getAddress: build.query<TAddressFullForm, void>({
      query: () => ({ url: 'seller/address' }),
      providesTags: ['Address'],
    }),
    putAdress: build.mutation<void, TAddressFullForm>({
      query: body => ({
        url: 'seller/address',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['Address'],
    }),
    deleteAccount: build.mutation<void, void>({
      query: () => ({
        url: 'seller/account',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetMeSellerQuery,
  useLazyGetMeSellerQuery,
  useDeleteAccountMutation,
  useGetBankInformationQuery,
  useGetAddressQuery,
  usePutAdressMutation,
} = meSellerApi;
