import { navItemsSeller } from '@/constants/data';
import SidebarDesktopSeller from '@/components/Seller/layout/sidebar-desktop-seller';
import Header from '@/components/Seller/layout/header';
import { ListingTable } from '@/components/Seller/table-listing';
import { useTranslation } from 'react-i18next';
import ListingInsightCard from '@/components/Seller/listing-insight-card';
import { useGetInventorySellerQuery } from '@/api/services/Seller/inventory';

export default function ListingSeller() {
  const { t } = useTranslation();

  const { data: inventory } = useGetInventorySellerQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <div className="lg:grid min-h-screen w-full lg:grid-cols-[250px_1fr]">
        <SidebarDesktopSeller navItems={navItemsSeller} title="" />
        <div className="flex flex-col">
          <Header navItems={navItemsSeller} title={t('my_inventory')} type="dashboard" />
          <main className="p-6">
            <ListingInsightCard />
            <ListingTable data={inventory || []} />
          </main>
        </div>
      </div>
    </>
  );
}
